import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContactFormateur from "../../components/contact/ContactInspirnaute";
import Header from "../../components/Header/Header";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import "./Contact.scss";
import {Link} from "react-router-dom";
import img10 from "../../assets/img/Colonnes copie (2) (1).png";

export default function Contact() {
  const { t } = useTranslation();
  
  return (
    <div className="iscriptionPage">
      {/* Arrow Scroll Top */}
      <ScrollToTopArrow />
      {/* Header section */}
      <section className="contact-section1">
        <Header></Header>
        <ScrollAnimation animateIn='flipInY'>
        <div className=" text-uppercase text-center roboto-condensed-bold fontSize-140 padding-contact1 text-spacing-3 padding-top-title yellow-color">
          {t("contact.section1.title")}
        </div>

        </ScrollAnimation>
        <img src={img10} alt="img10" className="img10" />

        <ScrollAnimation animateIn='flipInY'>
        <div className="yellow-color text-uppercase text-center roboto-black fontSize-50 line-height-description mt-30">
          {t("contact.section1.text")}
        </div>
        <div className="yellow-color text-uppercase text-center roboto-black fontSize-50 mb-5 line-height-description">
          {t("contact.section1.text1")}
        </div>
        </ScrollAnimation>
        <div className="roboto-medium description-contact1 fontSize-30">
          <div> {t("contact.section1.description")}</div>
          <div>{t("contact.section1.description1")}</div>
          <div>{t("contact.section1.description2")}</div>
          <div className="mb-5">{t("contact.section1.description3")}</div>
          
        </div>
        <ScrollAnimation animateIn='tada'>
          <div className="scroll-downs mt-5 scroll-padding">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
          </ScrollAnimation>
        {/* <div className="stars"></div> */}
        {/* <div className="overlay-section1"></div> */}

      </section>
      <section className="contact-section2">
        <div className="overlay-contact"></div>
        {/* <div className="stars1"></div> */}
        <div className="overlay-plane"></div>
        {/* <div className="overlay-contacts2"> </div> */}
        <ContactFormateur></ContactFormateur>
      </section>
      <section className="pg-contact-sec5">
        <div className="etoile-propos"></div>
        <div className="overlay-contact6"></div>
        <Row className="line-height-row">
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-90 yellow-color float-left text-spacing-7 pt-20 pb-5">
                {t("home.univers")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold ml-big text-uppercase fontSize-90 yellow-color text-spacing-3 infini">
                {t("home.infini")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                {t("home.contact")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <div
            >
              <p className="roboto-condensed-bold text-center fontSize-40 margin-bottom-contact">
                {t("home.adress")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 margin-bottom-contact">
                {t("home.phone")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 margin-bottom-contact ">
                {t("home.mail")}
              </p>
              <p className="roboto-condensed text-center fontSize-25 notice text-underline">
                <Link to="/notice">{t("home.mention")}</Link>
              </p>
            </div>
          </Col>
        </Row>
      </section>

    </div>
  );
}
