import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Context as InscriptionRecruter } from "../../context/reducers/InscriptionReducer";
import { ErrorFeild, ErrorClass } from "../../services/Controller/ValidationForm";
import { emailPattern } from "../../services/Controller/PattenService";
import AlertModal from "../alert/alert";

// validation Form contact
const validationContact = Yup.object({
  email: Yup.string()
    .required("Email obligatoire")
    .email("Email invalide")
    .matches(emailPattern, "Email invalide"),
  firstname: Yup.string().required("Nom obligatoire"),
  lastname: Yup.string().required("Prénom obligatoire"),
  message: Yup.string().required("Message obligatoire"),
});

export default function ContactInspirnaute() {
  /* Context Api*/
  const { sendContact, state } = useContext(InscriptionRecruter);
  /* show alert */
  const [show, setShow] = useState(false);
  /* */
  useEffect(() => {
    if (state.toastContent && (state.toastContent.type === "Error_CONTACT" || state.toastContent.type === "Success_CONTACT")) {
      setShow(true);
    }
  }, [state])

  /* Form cantact */
  const contactForm = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      message: "",
      post: ""
    },
    validationSchema: validationContact,
    onSubmit: (values) => {
      const data = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        post: values.post,
        message: values.message
      };
      contactForm.resetForm();
      sendContact(data);
      window.scrollTo(0, 0);
    },
  });


  return (
    <>
      {/* Alert */}
      <AlertModal show={show} setShow={setShow} content={state.toastContent ? state.toastContent.body : ""}
        type={state.toastContent && state.toastContent.type === "Error_CONTACT" ? "error" : null} />
      {/* contact form */}
      <div className="contact-form col-lg-4 col-md-4 col-sm-12">
        <div className="contact-title pb-5">Contactez nous</div>
        <Form onSubmit={contactForm.handleSubmit}>
          {/* Name */}
          <Form.Group>
            <Form.Label className="title-inscri required">Nom</Form.Label>
            <Form.Control
              className={ErrorClass(contactForm, "firstname") || "form-control-new"}
              type="text" name="firstname" id="firstname"
              placeholder="Saisir votre nom"
              onChange={contactForm.handleChange}
              value={contactForm.values.firstname}
              onBlur={contactForm.handleBlur}
            />
            {ErrorFeild(contactForm, "firstname")}
          </Form.Group>
          {/* last name */}
          <Form.Group>
            <Form.Label className="title-inscri required">Prénom</Form.Label>
            <Form.Control
              className={ErrorClass(contactForm, "lastname") || "form-control-new"}
              type="text" name="lastname" id="lastname"
              placeholder="Saisir votre prénom"
              onChange={contactForm.handleChange}
              value={contactForm.values.lastname}
              onBlur={contactForm.handleBlur}
            />
            {ErrorFeild(contactForm, "lastname")}
          </Form.Group>
          {/* Email */}
          <Form.Group>
            <Form.Label className="title-inscri required">Email</Form.Label>
            <Form.Control
              type="email"
              id="email"
              placeholder="Saisir votre adresse email"
              name="email"
              className={ErrorClass(contactForm, "email")}
              onChange={contactForm.handleChange}
              value={contactForm.values.email}
              onBlur={contactForm.handleBlur}
            />
            {ErrorFeild(contactForm, "email")}
          </Form.Group>
          <Form.Group >
            <Form.Label className="title-inscri">Poste actuel</Form.Label>
            <Form.Select name="post" id="post" className={ErrorClass(contactForm, "post")}
              onChange={contactForm.handleChange}
              onBlur={contactForm.handleBlur}
              value={contactForm.values.post}>
              <option className="text-bold" value=""> Séléctionnez un poste </option>
              <option value="Directeur">Directeur</option>
              <option value="Manager">Manager</option>
              <option value="Chef du projet">Chef du projet</option>
              <option value="Responsable RH">Responsable RH</option>
              <option value="Directeur RH">Directeur RH</option>
              <option value="Responsable recrutement">Responsable recrutement</option>
              <option value="Assistant de direction">Assistant de direction</option>
              <option value="Ingénieur d'affaire">Ingénieur d'affaire</option>
              <option value="Autre">Autre</option>
            </Form.Select>
            {ErrorFeild(contactForm, "post")}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="title-inscri required">Message</Form.Label>
            <Form.Control
              as="textarea" rows={4} id="message" name="message"
              placeholder="Saisir votre message"
              className={ErrorClass(contactForm, "message")}
              onChange={contactForm.handleChange}
              value={contactForm.values.message}
              onBlur={contactForm.handleBlur}
            />
            {ErrorFeild(contactForm, "message")}
          </Form.Group>
          <div className="text-center">
            <Button variant="primary" type="submit" className="button-send align-center">
              Envoyer
            </Button>
          </div>
        </Form>
      </div >
    </>
  );
}
