import React, { useContext, useEffect, useState } from "react";
import "./AnnexeAdmin.scss";
import {
  Tabs,
  Tab,
  Table,
  Modal,
  Form,
  Button,
  Row,
  Col,
  ModalBody,
} from "react-bootstrap";
import { FaEdit, FaTrashAlt, FaSearch } from "react-icons/fa";
import { Context as AdministratorContext } from "../../../context/reducers/AdminReducer";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import {
  ErrorFeild,
  ErrorClass,
} from "../../../services/Controller/ValidationForm";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactTooltip from "react-tooltip";
import Paginate from "../../../components/Paginate/Paginate";

/* Validation Form Group */
// const validationFormGroup = Yup.object({
//   name: Yup.string().required("Champ obligatoire"),
// });

/* Annexe Page */
function AnnexeAdmin() {
  /* Context Api */
  const {
    getAllSkills,
    addSkill,
    editSkill,
    deleteSkill,
    dumpSkill,
    getAllTypeSkills,
    addTypeSkill,
    editTypeSkill,
    deleteTypeSkill,
    dumpTypeSkill,
    getAllDomainsRecruiter,
    addDomainsRecruiter,
    editDomainsRecruiter,
    deleteDomainsRecruiter,
    dumpDomainRecruiter,
    hideToast,
    state,
  } = useContext(AdministratorContext);

  /* Condidatures lists */
  const [allSkills, setAllSkills] = useState([]);
  const [allTypeSkills, setAllTypeSkills] = useState([]);
  const [allDomainsRecruiters, setAllDomainsRecruiters] = useState([]);

  /* Modals */
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [labelIsModified, setLabelIsModfied] = useState(false);

  /* Tab Active Value */
  const [defaultTabValue, setDefaultTabValue] = useState("Skills");

  /* filter Input */
  const [filterValue, setFilterValue] = useState("");

  /* Table Content */
  const [displayedColumns, setDisplayedColumns] = useState([
    "Domaine d’expertise",
    "Nom du formateur",
    "Durée de la formation",
    "Commentaire",
    "Catégorie de formation",
    "",
  ]);
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  /* eslint-disable */
  useEffect(() => {
    getAllSkills();
    getAllTypeSkills();
    getAllDomainsRecruiter();
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (state.allSkills) {
      setAllSkills(state.allSkills);
      if (defaultTabValue === "Skills") setDisplayedData(state.allSkills);
    }
    if (state.allTypeSkills) {
      setAllTypeSkills(state.allTypeSkills);
      if (defaultTabValue === "TypeSkills")
        setDisplayedData(state.allTypeSkills);
    }
    if (state.allDomainsRecruiters) {
      setAllDomainsRecruiters(state.allDomainsRecruiters);
      if (defaultTabValue === "DomainRecruiter")
        setDisplayedData(state.allDomainsRecruiters);
    }
  }, [state, defaultTabValue]);

  /* Refresh list Skills After Add | Edit | Delete */
  useEffect(() => {
    if (state.skill) {
      setFilterValue("");
      dumpSkill();
      getAllSkills();
    }
  }, [dumpSkill, getAllSkills, state.skill]);

  /* Refresh list Types Skills After Add | Edit | Delete */
  useEffect(() => {
    if (state.typeSkill) {
      setFilterValue("");
      dumpTypeSkill();
      getAllTypeSkills();
    }
  }, [dumpTypeSkill, getAllTypeSkills, state.typeSkill]);

  /* Refresh list Domain Recruiter After Add | Edit | Delete */
  useEffect(() => {
    if (state.domainrecruiter) {
      setFilterValue("");
      dumpDomainRecruiter();
      getAllDomainsRecruiter();
    }
  }, [dumpDomainRecruiter, getAllDomainsRecruiter, state.domainrecruiter]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);

  /* Content Annexe List */
  function showAnnexeContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length ? getList() : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* listings */
  function getList() {
    return currentItems.map((el, index) => {
      if (defaultTabValue === "TypeSkills") {
        console.log(el.name)
        return (
          <tr
            className="table-content table-padding"
            key={defaultTabValue + index}
          >
            <td className="w-250">
              <p>{el.name ? el.name : "- - -"}</p>
            </td>
            <div className="float-right pointer d-flex">
                <ReactTooltip id="Consult" place="bottom">
                  {" "}
                  Modifier{" "}
                </ReactTooltip>
                <ReactTooltip id="Delete" place="bottom">
                  {" "}
                  Supprimer{" "}
                </ReactTooltip>
                <span
                  className="btn-detail blue"
                  onClick={() => {
                    EditItem(el);
                  }}
                >
                  <FaEdit data-tip data-for="Consult" className="mr-20" />
                </span>
                <span
                  className="btn-detail red"
                  onClick={() => {
                    setSelectedItem(el.id);
                    setShow(true);
                  }}
                >
                  <FaTrashAlt data-tip data-for="Delete" />
                </span>
              </div>
          </tr>
        );
      } else {
        return (
          <tr
            className="table-content table-padding"
            key={defaultTabValue + index}
          >
            <td className="w-250">
              <p>{el.domain ? el.domain : "- - -"}</p>
            </td>
            <td className="w-250">
              <p>{el.name ? el.name : "- - -"}</p>
            </td>
            <td className="w-250">
              <p>{el.duree ? el.duree : "- - -"}</p>
            </td>
            <td className="w-250">
              <p>{el.comment ? el.comment : "- - -"}</p>
            </td>
            {defaultTabValue === "Skills" && (
              <td className="w-250">
                <p>{el.nameType ? el.nameType : "- - -"}</p>
              </td>
            )}
            <td>
              <div className="float-right pointer d-flex">
                <ReactTooltip id="Consult" place="bottom">
                  {" "}
                  Modifier{" "}
                </ReactTooltip>
                <ReactTooltip id="Delete" place="bottom">
                  {" "}
                  Supprimer{" "}
                </ReactTooltip>
                <span
                  className="btn-detail blue"
                  onClick={() => {
                    EditItem(el);
                  }}
                >
                  <FaEdit data-tip data-for="Consult" className="mr-20" />
                </span>
                <span
                  className="btn-detail red"
                  onClick={() => {
                    setSelectedItem(el.id);
                    setShow(true);
                  }}
                >
                  <FaTrashAlt data-tip data-for="Delete" />
                </span>
              </div>
            </td>
          </tr>
        );
      }
    });
  }

  /* Add Skill | Type Skill | Domain Recruiter */
  function AddItem() {
    setSelectedItem(null);
    FormGroup.resetForm();
    setModalShow(true);
  }

  /* Edit Skill | Type Skill | Domain Recruiter */
  function EditItem(el) {
    setSelectedItem(el.id);
    FormGroup.setFieldValue("name", el.name);
    if (defaultTabValue === "Skills")
      FormGroup.setFieldValue("type", el.idType);
    setModalShow(true);
  }

  /* Delete Skill | Type Skill | Domain Recruiter */
  function DeleteItem() {
    if (defaultTabValue === "Skills") deleteSkill(selectedItem);
    else if (defaultTabValue === "TypeSkills") deleteTypeSkill(selectedItem);
    else deleteDomainsRecruiter(selectedItem);
  }

  /* Form Group */
  const FormGroup = useFormik({
    enableReinitialize: true,
    initialValues:
      defaultTabValue === "Skills"
        ? {
            name: "",
            type:
              allTypeSkills && allTypeSkills.length
                ? allTypeSkills[0].id
                : null,
          }
        : { name: "" },
    // validationSchema: validationFormGroup,
    onSubmit: (values) => {
      const submitValues = labelIsModified ? values : { type: values.type };

      if (!selectedItem) {
        if (defaultTabValue === "Skills") {
          addSkill(submitValues);
        } else if (defaultTabValue === "TypeSkills") {
          addTypeSkill({name :submitValues.type}); //tall
        } else {
          addDomainsRecruiter(submitValues);
        }
      } else {
        if (defaultTabValue === "Skills") {
          editSkill(submitValues, selectedItem);
        } else if (defaultTabValue === "TypeSkills") {
          editTypeSkill(submitValues, selectedItem);
        } else {
          editDomainsRecruiter(submitValues, selectedItem);
        }
      }
      setModalShow(false);
      setLabelIsModfied(false);
    },
  });

  /* Open Modal */
  function openModal() {
    return (
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="md"
        centered
      >
        <Form onSubmit={FormGroup.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Ajouter{" "}
              {defaultTabValue === "Skills"
                ? "une formation"
                : defaultTabValue === "TypeSkills"
                ? "une catégorie de formation"
                : "un domaine d’expertise"}
            </Modal.Title>
          </Modal.Header>
          {defaultTabValue === "TypeSkills" ? (
            <Modal.Body>
             
             <Form.Label className="text-Label-modal">
                  {" "}
                  Choisir une catégorie de la formation{" "}
                </Form.Label>
                <Form.Group>
                <Form.Control
                  className={ErrorClass(FormGroup, "name")}
                  type="text"
                  name="type"
                  id="type"
                  placeholder="Saisissez une nouvelle catégorie"
                  onChange={(event) => {
                    FormGroup.handleChange(event);
                    setLabelIsModfied(true);
                  }}
                  onBlur={FormGroup.handleBlur}
                  value={FormGroup.values.type}
                />
                {ErrorFeild(FormGroup, "name")}
              </Form.Group>
              
            </Modal.Body>
          ) : (
            <Modal.Body>
              <Form.Group>
                <Form.Label className="title-inscri">
                  Domaine d’expertise
                </Form.Label>
                <Form.Select
                  name="domain"
                  id="domain"
                  onChange={FormGroup.handleChange}
                  onBlur={FormGroup.handleBlur}
                  value={FormGroup.values.domain}
                >
                  <option className="text-bold" defaultValue value="">
                    {" "}
                    Séléctionnez une option{" "}
                  </option>
                  <option value="Finance et Comptabilité">
                    Finance et Comptabilité
                  </option>
                  <option value="Ressources Humaines">
                    Ressources Humaines
                  </option>
                  <option value="Marketing et Communication">
                    Marketing et Communication
                  </option>
                  <option value="Vente et Développement Commercial">
                    Vente et Développement Commercial
                  </option>
                  <option value="Technologies de l'Information (IT)">
                    Technologies de l'Information (IT)
                  </option>
                  <option value="Ingénierie et Production">
                    Ingénierie et Production
                  </option>
                  <option value="Logistique et Supply Chain">
                    Logistique et Supply Chain
                  </option>
                  <option value="Droit et Juridique">Droit et Juridique</option>
                  <option value="Gestion de Projet">Gestion de Projet</option>
                  <option value="Santé et Services Sociaux">
                    Santé et Services Sociaux
                  </option>
                  <option value="Expertise numérique">
                    Expertise numérique
                  </option>
                  <option value="Bien être">Bien être</option>
                  <option value="Soft Skill">Soft Skill</option>
                  <option value="Musique">Musique</option>
                  <option value="Art">Art</option>
                  <option value="Sport">Sport</option>
                  <option value="Autre">Autre</option>
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-Label-modal">
                  {" "}
                  Nom de formateur{" "}
                </Form.Label>
                <Form.Control
                  className={ErrorClass(FormGroup, "name")}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nom de formateur"
                  onChange={(event) => {
                    FormGroup.handleChange(event);
                    setLabelIsModfied(true);
                  }}
                  onBlur={FormGroup.handleBlur}
                  value={FormGroup.values.name}
                />
                {ErrorFeild(FormGroup, "name")}
              </Form.Group>
              <Form.Group>
                <Form.Label className="text-Label-modal">
                  {" "}
                  Durée de formation{" "}
                </Form.Label>
                <Form.Control
                  className={ErrorClass(FormGroup, "name")}
                  type="text"
                  name="duree"
                  id="duree"
                  placeholder="Saisissez la durée de formation"
                  onChange={(event) => {
                    FormGroup.handleChange(event);
                    setLabelIsModfied(true);
                  }}
                  onBlur={FormGroup.handleBlur}
                  value={FormGroup.values.duree}
                />
                {ErrorFeild(FormGroup, "name")}
              </Form.Group>

              <Form.Group>
                <Form.Label className="text-Label-modal">
                  {" "}
                  Commentaire{" "}
                </Form.Label>
                <Form.Control
                  className={ErrorClass(FormGroup, "name")}
                  type="text"
                  name="comment"
                  id="comment"
                  placeholder="Saisissez votre commentaire"
                  as="textarea"
                  rows={3}
                  onChange={(event) => {
                    FormGroup.handleChange(event);
                    setLabelIsModfied(true);
                  }}
                  onBlur={FormGroup.handleBlur}
                  value={FormGroup.values.comment}
                />
                {ErrorFeild(FormGroup, "duree")}
              </Form.Group>

              {/* {defaultTabValue === "Skills" ? ( */}
              <Form.Group>
                <Form.Label className="text-Label-modal">
                  {" "}
                  Choisissez une catégorie de formation{" "}
                </Form.Label>
                <Form.Select
                  className={ErrorClass(FormGroup, "type")}
                  name="type"
                  id="type"
                  onChange={FormGroup.handleChange}
                  onBlur={FormGroup.handleBlur}
                  value={FormGroup.values.type}
                >
                  {allTypeSkills && allTypeSkills.length
                    ? allTypeSkills.map((el, index) => {
                        return (
                          <option
                            className="color-black"
                            key={"option" + index}
                            value={el.id}
                          >
                            {el.name}
                          </option>
                        );
                      })
                    : null}
                </Form.Select>
              </Form.Group>
              {/* ) : null} */}
            </Modal.Body>
          )}
          <Modal.Footer className="justify-content-center">
            <Button type="submit" className="save-button green shadow-unset">
              Enregistrer
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  /* Tabulation onChange */
  function changeTabulation(tab) {
    setFilterValue("");
    if (tab === "Skills") {
      setDisplayedColumns([
        "Domaine d’expertise",
        "Nom de formateur",
        "Durée de formation",
        "Commentaire",
        "Catégorie de la formation",
        "",
      ]);
      setDisplayedData(allSkills);
    } else {
      setDisplayedColumns(["Libellé", ""]);
      if (tab === "TypeSkills") {
        setDisplayedColumns(["Catégorie de la formation", ""]);
        setDisplayedData(allTypeSkills);
      } else setDisplayedData(allDomainsRecruiters);
    }
    setDefaultTabValue(tab);
  }

  /* filter List */
  function filterList(event) {
    const value = event.target.value.toLowerCase();
    setFilterValue(value);
    const AllList =
      defaultTabValue === "Skills"
        ? allSkills
        : defaultTabValue === "TypeSkills"
        ? allTypeSkills
        : allDomainsRecruiters;
    const list = AllList.filter(
      (el) => value === "" || el.name?.toLowerCase()?.indexOf(value) !== -1
    );
    setDisplayedData(list);
  }

  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={
          "Vous êtes sûr de vouloir supprimer cette " +
          (defaultTabValue === "Skills"
            ? "Formation"
            : defaultTabValue === "TypeSkills"
            ? "catégorie de formation"
            : "domaine Entreprise ") +
          " ?"
        }
        setSelectedItem={setSelectedItem}
        Action={DeleteItem}
      />
      <div id="main-without-header" className="main-without-header">
        <Row>
          <Col lg={6} md={12} xs={12}>
            <label className="title-page">Catalogue de formation</label>
          </Col>
          <Col lg={3} md={12} xs={12}>
            <Form>
              <Form.Group className="position-relative">
                <FaSearch className="position-absolute searsh-Input" />
                <Form.Control
                  type="text"
                  placeholder="Rechercher"
                  onChange={(e) => filterList(e)}
                  value={filterValue}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col lg={3} md={12} xs={12}>
            <Button
              onClick={AddItem}
              className={"float-right save-button green shadow-unset"}
            >
              Ajouter{" "}
              {defaultTabValue === "Skills"
                ? "une formation"
                : defaultTabValue === "TypeSkills"
                ? "une catégorie de formation"
                : "un domaine d’expertise"}
            </Button>
          </Col>
        </Row>
        <Tabs
          activeKey={defaultTabValue}
          onSelect={(k) => changeTabulation(k)}
          className="mt-3 mb-3 w-100"
        >
          <Tab
            eventKey="Skills"
            title={
              defaultTabValue === "Skills"
                ? "Formations (" +
                  (displayedData ? displayedData.length : 0) +
                  ")"
                : "Formations (" + (allSkills ? allSkills.length : 0) + ")"
            }
          >
            {showAnnexeContent()}
          </Tab>
          <Tab
            eventKey="TypeSkills"
            title={
              defaultTabValue === "TypeSkills"
                ? "Catégorie de formation (" +
                  (displayedData ? displayedData.length : 0) +
                  ")"
                : "Catégorie de formation (" +
                  (allTypeSkills ? allTypeSkills.length : 0) +
                  ")"
            }
          >
            {showAnnexeContent()}
          </Tab>
          {/* <Tab
            eventKey="DomainRecruiter"
            title={
              defaultTabValue === "DomainRecruiter"
                ? "Domaine d’expertise (" +
                  (displayedData ? displayedData.length : 0) +
                  ")"
                : "Domaine d’expertise (" +
                  (allDomainsRecruiters ? allDomainsRecruiters.length : 0) +
                  ")"
            }
          >
            {showAnnexeContent()}
          </Tab> */}
        </Tabs>
      </div>
      {/* Modal */}
      {openModal()}
    </>
  );
}

/* Export Annexe component */
export default AnnexeAdmin;
