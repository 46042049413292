import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import {
  ErrorFeild,
  ErrorClass,
} from "../../services/Controller/ValidationForm";
import "./DocumentPersonnel.scss";
export const DocumentPersonnel = ({
  modelShow,
  setModelShow,
  addDocument,
  ediDocument,
  edit,
  reload,
}) => {
  /* Context Api */
  const validationFiles = Yup.object({
    cin: Yup.mixed().required("fichier obligatoire").test(
      "fileType",
      "format de fichier non pris en charget",
      function () {
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf",
          null,
          undefined,
          "",
        ];
        return (
          formik.values.cin === null ||
          formik.values.cin === undefined ||
          (formik.values.cin &&
            SUPPORTED_FORMATS.includes(formik.values.cin?.type))
        );
      }
    ),
    insurance: Yup.mixed().test(
      "fileType",
      "format de fichier non pris en charge",
      function () {
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf",
          undefined,
          null,
        ];
        return (
          formik.values.insurance === null ||
          formik.values.address === undefined ||
          (formik.values.insurance &&
            SUPPORTED_FORMATS.includes(formik.values.insurance?.type))
        );
      }
    ),
    address: Yup.mixed().test(
      "fileType",
      "format de fichier non pris en charge",
      function (value) {
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf",
          undefined,
          null,
        ];
        return (
          formik.values.address === null ||
          formik.values.address === undefined ||
          (formik.values.address &&
            SUPPORTED_FORMATS.includes(formik.values.address?.type))
        );
      }
    ),
    rib: Yup.mixed().test(
      "fileType",
      "format de fichier non pris en charge",
      function () {
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf",
          undefined,
          null,
        ];
        return (
          formik.values.rib === null ||
          formik.values.rib === undefined ||
          (formik.values.rib &&
            SUPPORTED_FORMATS.includes(formik.values.rib?.type))
        );
      }
    ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cin: edit ? edit[1]?.doc_name : {},
      insurance: edit ? edit[2]?.doc_name : {},
      address_proof: edit ? edit[3]?.doc_name : {},
      rib: edit ? edit[4]?.doc_name : {},
    },
    validationSchema: validationFiles,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("cin", values.cin);
      formData.append("insurance", values.insurance);
      formData.append("address_proof", values.address_proof);
      formData.append("rib", values.rib);
      if (edit) ediDocument(formData, edit ? edit[0]?.id : null);
      else addDocument(formData);
      formik.resetForm();
      handleClose();
    },
  });
  /* Close Modal Mission */
  const handleClose = (e) => {
    setModelShow(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal show={modelShow} onHide={handleClose} size="lg" centered>
        <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton className="border-0 text-black">
          <Modal.Title id="contained-modal-title-vcenter">
            {/* {!edit ? 'Ajouter mission' : 'Modifier mission'} */}
            Documents personnels
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 text-black">
          <Form.Group className="mb-3">
            <Form.Label>Carte d'identité ( recto/verso )</Form.Label>
            <Form.Control
              className={ErrorClass(formik, "cin")}
              type="file"
              name="cin"
              id="cin"
              onChange={(event) => {
                formik.setFieldValue("cin", event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
            />
            {ErrorFeild(formik, "cin")}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Assurance professionnelle</Form.Label>
            <Form.Control
              className={ErrorClass(formik, "insurance")}
              type="file"
              name="insurance"
              id="insurance"
              onChange={(event) => {
                formik.setFieldValue("insurance", event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
            />
            {ErrorFeild(formik, "insurance")}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Justificatif de domicile</Form.Label>
            <Form.Control
              className={ErrorClass(formik, "address_proof")}
              type="file"
              name="address_proof"
              id="address_proof"
              onChange={(event) => {
                formik.setFieldValue(
                  "address_proof",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={formik.handleBlur}
            />
            {ErrorFeild(formik, "address")}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>RIB</Form.Label>
            <Form.Control
              className={ErrorClass(formik, "rib")}
              type="file"
              name="rib"
              id="rib"
              onChange={(event) => {
                formik.setFieldValue("rib", event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
            />
            {ErrorFeild(formik, "rib")}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-center button-form-document">
            <Button
            disabled={edit ? !formik.dirty : formik.dirty}
              type="submit"
              variant="success"
              className="button-form-document"
            >
              {edit ? "Modifier" : "Ajouter"}
            </Button>
          </div>
        </Modal.Footer>
        </Form>
      </Modal>
   </>
  );
};
