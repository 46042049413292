import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Header from "../../components/Header/Header";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import overlay from "../../assets/img/book3.png";
import elipse from "../../assets/img/inscription/etoile-inscrit8.png";
import fusil from "../../assets/img/inscription/inscrit-anim.png";
import background from "../../assets/img/inscription/diamant1.png";
import cosmonaute1 from "../../assets/img/inscription/StatueHomme.png";
import cosmonaute2 from "../../assets/img/inscription/StatueFemme.png";
import cosmonaute3 from "../../assets/img/inscription/StatueHomme.png";
import cosmonaute4 from "../../assets/img/inscription/StatueFemme.png";
import cercle from "../../assets/img/inscription/cercle.png";
import cosmonaute from "../../assets/img/inscription/inscrit-cosmonaute.png";
import roverlay from "../../assets/img/inscription/overlay.png";
import galaxy from "../../assets/img/inscription/bggalaxies.png";
import yellowcercle from "../../assets/img/inscription/yellow-cercle.png";
import spaceship from "../../assets/img/inscription/spaceship.png";
import Planet from "../../assets/img/inscription/Planet.png";
import flag from "../../assets/img/inscription/flag.png";
import planet from "../../assets/img/inscription/Planet-1.png";
import rock from "../../assets/img/inscription/rock.png";
import lamp from "../../assets/img/inscription/lampe.png";
import elipse1 from "../../assets/img/inscription/elipse10.png";
import screen from "../../assets/img/inscription/r-inscri2.png";
import "./RecruterInscription.scss";
import RecruterInscrit from "../../components/RecruterInscrit/RecruterInscrit";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import { scroller } from "react-scroll";
import { Link } from "react-router-dom";
import img6 from "../../assets/img/6.png";
import imgOne from "../../assets/img/1.png";
import imgTwo from "../../assets/img/temple.png";
import imgMembers1 from "../../assets/img/6.png";
import imgMembers2 from "../../assets/img/6.png";
import femme from "../../assets/img/3 (2).png";
import mobilepic from "../../assets/img/téléchargement (19).png";
import mobilepic2 from "../../assets/img/téléchargement (20).png";


export default function RecruterInscription() {
  /* use translate */
  const { t } = useTranslation();

  /* scroll to section */
  const scrollToSection = () => {
    scroller.scrollTo("active", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      <section className="r-inscription1">
        <Header />
        <div className="r-overlay"></div>
        {/* <div className="r-etoile"></div> */}
       
        <img src={imgOne} alt="bubble" className="imgOne" />

        <img src={imgTwo} alt="bubble" className="imgTwo" />

        <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="mt-15"
        >
          <p className="yellow-color text-uppercase text-center roboto-condensed-bold fontSize-140 text-spacing margin-title">
            {t("recruteinscription.section1.title")}
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInY" animateOut="flipOutY">
          <p className="fontSize-50 yellow-color text-uppercase text-center pt-20">
            {t("recruteinscription.section1.text")}
          </p>
        </ScrollAnimation>
        <div className="white-color fontSize-30 text-center roboto-medium">
          <p className="text-spacing">
            {t("recruteinscription.section1.description1")}
          </p>
          <p>{t("recruteinscription.section1.description2")}</p>
        </div>
        <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="padding-button-inscrit"
        >
          <div className="d-flex justify-content-center">
            <Button
              onClick={scrollToSection}
              variant="secondary"
              type="button"
              className="text-center btn-recrute "
            >
              {t("recruteinscription.section1.button")}
            </Button>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="tada">
          <div className="scroll-downs mt-5 ">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </ScrollAnimation>
      </section>
      {/*  */}
      <section className="r-inscription2  pt-xl-4 pt-lg-4 pt-md-0 pt-sm-0">
        <div>
          <p className="text-center roboto-black text-uppercase fontSize-90 yellow-color pt-lg-4 ">
            {t("recruteinscription.section2.title")}
          </p>
          <div className="text-center roboto-medium fontSize-30">
            <p className="text-spacing  plr">
              {t("recruteinscription.section2.text1")}
            </p>
            <p>{t("recruteinscription.section2.text2")}</p>
          </div>
          {/* <Image src={screen} alt="screen" className="r-screen"></Image> */}
        </div>
        <div className="d-flex align-items-center margin-flex">
          <div>
            <Image src={overlay} className="r-overlay book"></Image>
          </div>
          <div className="mneg">
            <div className="roboto-condensed-bold text-uppercase fontSize-90 yellow-color m-mobile">
              <div className="text-spacing-5 div-one fontSize-90">
                {t("recruteinscription.section2.description1")}
              </div>
              <div>{t("recruteinscription.section2.description2")}</div>
            </div>
            <div className="roboto-condensed-bold fontSize-35 white-color m-mobile">
              <p className="text-spacing-1">
                {t("recruteinscription.section2.description3")}
              </p>
            </div>
            <div className="button-position">
              <Button
                onClick={scrollToSection}
                type="button"
                className="text-center btn-recrute"
              >
                {t("recruteinscription.section1.button")}
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="r-inscription3">
        {/* <div>
          <div className="text-center text-uppercase roboto-condensed-bold fontSize-140 text-transparent padding-title">
            {t("recruteinscription.section3.title")}
          </div>
          <div className="elipse"></div>
          <div className="elipse1"></div>
          <div className="fontSize-30 text-center roboto-medium color-white opacity-text">
            <p className="text-spacing">
              {t("recruteinscription.section3.text1")}
            </p>
          </div>
        </div> */}
        <div>
          {/* <Image src={fusil} className="inscrit-fusil"></Image> */}
        </div>
        <div className="fontSize-100 roboto-condensed-bold yellow-color2 mmob">
          <div className="padding-description">
            {t("recruteinscription.section3.description1")}
          </div>
          <div className="padding-description1 pt-20">
            {t("recruteinscription.section3.description2")}
          </div>
        </div>
      </section>
      <section className="r-inscription4 mobile">
        {/* <Image src={background} className="diamant-inscrit"></Image> */}
        <Image src={mobilepic} className="mobilepic"></Image>
        <Image src={mobilepic2} className="mobilepic"></Image>
        <div className="d-flex d-flex-1 members mobile">
          <div>
            <Image
              src={cosmonaute2}
              className="animate-3 position-image "
            ></Image>
            <div className="fontSize-25 max-width">
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent2")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent23")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent21")}
              </p>
              <p className="text-center">
                {t("recruteinscription.section3.agent22")}
              </p>{" "}
            </div>
          </div>
          <div className="delete-image">
            <Image src={cosmonaute3} className="animate-3 "></Image>
            <div className="fontSize-25 max-width">
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent3")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent33")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent31")}
              </p>
              <p className="text-center">
                {t("recruteinscription.section3.agent32")}
              </p>{" "}
            </div>
          </div>
          <div className="delete-image">
            <Image src={cosmonaute4} className="animate-3 "></Image>
            <div className="fontSize-25 max-width">
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent4")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent43")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent41")}
              </p>
              <p className="text-center">
                {t("recruteinscription.section3.agent42")}
              </p>{" "}
            </div>
          </div>
          <div id="delete-div">
            <Image src={cosmonaute1} className="animate-3 "></Image>
            <div className="fontSize-25 max-width" id="delete-div">
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent1")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent13")}
              </p>
              <p className="text-center text-spacing">
                {t("recruteinscription.section3.agent11")}
              </p>
              <p className="text-center">
                {t("recruteinscription.section3.agent12")}
              </p>{" "}
            </div>
          </div>
        </div>
        <Image src={imgMembers1} className="animate-3 imgmembers1"></Image>
        <Image src={imgMembers2} className="animate-3 imgmembers2"></Image>
        <div className="d-flex  d-flex-2 justify-content-between"></div>
      </section>
      {/*  */}
      {/* <section className="r-inscription5">
        <div className="d-flex">
          <div>
            <Image src={cercle} className="cosmonaute1 animate-3"></Image>
            <Image src={cosmonaute} className="cosmonaute animate-3"></Image>
          </div>
          <div>
            <div className="roboto-condensed-bold purple-Color fontSize-70 text-uppercase margin-title">
              <div className="text-spacing-2 float-left-div">{t("recruteinscription.section4.title")}</div>
              <div className="text-spacing-2 float-left-div">{t("recruteinscription.section4.title1")}</div>
              <div className="text-spacing-2 float-left-div">{t("recruteinscription.section4.title2")}</div>
              <div>{t("recruteinscription.section4.title3")}</div>
            </div>
            <div className="white-color roboto-medium fontSize-30 pt-5 padding-text">
              <div>{t("recruteinscription.section4.text1")}</div>
              <div>{t("recruteinscription.section4.text2")}</div>
              <div>{t("recruteinscription.section4.text3")}</div>
            </div>
          </div>
        </div>
      </section> */}
      {/*  */}
      {/* <section className="r-inscription6">
        <div className="yellow-color roboto-condensed-bold fontSize-90 text-center pt-5">
          <div className="text-uppercase"> {t("recruteinscription.section5.title1")} </div>
          <div className="text-uppercase"> {t("recruteinscription.section5.title2")} </div>
        </div>
        <Image src={yellowcercle} className="pl-3 display-image"></Image>
        <div>
          <Image src={galaxy} className="r-screen"></Image>
          <Image src={roverlay} className="overlay-inscrit "></Image>
        </div>
      </section> */}
      {/*  */}
      {/* <section className="r-inscription8">
        <div className="d-flex justify-content-around spacing-flex">
          <Row>
            <Col lg={3} md={6} xs={12}>
              <Image src={spaceship} alt="space" className="animate-3 image-position"></Image>
            </Col>
            <Col lg={9} md={6} xs={12}>
              <div className="padding-t">
                <div className="fontSize-60 yellow-color roboto-condensed text-uppercase">
                  <div className="text-spacing-2">
                    {t("recruteinscription.section7.title")}
                  </div>
                  <div className="">{t("recruteinscription.section7.title2")}</div>
                </div>
                <div className="roboto-condensed-bold white-color fontSize-90 text-uppercase div-height">
                  <div className="text-spacing">
                    {t("recruteinscription.section7.description1")}
                  </div>
                  <div className="text-spacing">
                    {t("recruteinscription.section7.description2")}
                  </div>
                  <div>{t("recruteinscription.section7.description3")}</div>
                </div>
                <div className="d-flex-1 justify-content-between">
                  <div>
                    <Button onClick={scrollToSection} variant="secondary" type="button" className="text-center btn-recrute">
                      {t("recruteinscription.section1.button")}
                    </Button>
                  </div>
                  <div>
                    <Image src={rock}></Image>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Image src={elipse} className="elipse"></Image>
        </div>
      </section> */}
      {/* <section className="r-inscription9">
        <div className="masques"></div>
        <div className="padding-l">
          <div className="green-color fontSize-60 roboto-condensed mb-3 mt-3">
            <div className="text-spacing-2"> {t("recruteinscription.section8.description1")} </div>
            <div className="text-spacing-2"> {t("recruteinscription.section8.description2")} </div>
            <div>{t("recruteinscription.section8.description3")}</div>
          </div>
          <div className="white-color fontSize-40 roboto-regular">
            <div>{t("recruteinscription.section8.text1")}</div>
            <div>{t("recruteinscription.section8.text2")}</div>
          </div>
          <Image src={Planet} className="planet"></Image>
        </div>
      </section> */}
      {/* <section className="r-inscription10">
        <Image src={lamp} className="lamp"></Image>
        <div className="white-color fontSize-90 roboto-condensed-bold text-uppercase padding-l">
          <div className="text-spacing-2"> {t("recruteinscription.section9.title1")} </div>
          <div className="text-spacing-2"> {t("recruteinscription.section9.title2")} </div>
          <div> {t("recruteinscription.section9.title3")} </div>
        </div>
        <div className="d-flex-1 justify-content-around">
          <div>
            <Image src={elipse1} className="elipse1"></Image>
            <Image src={flag} className="flag"></Image>
          </div>
          <div className="white-color fontSize-40 roboto-regular text-uppercase align-self-center">
            <div className="float-div">{t("recruteinscription.section9.description1")}</div>
            <div>{t("recruteinscription.section9.description2")}</div>
          </div>
        </div>
      </section> */}
      <section className="r-inscription11" id="r-inscription11">
        {/* <div className="roboto-condensed-bold yellow-color fontSize-70 text-center">
          <div className="text-spacing-2 text-uppercase">
            {t("recruteinscription.section10.title1")}
          </div>
          <div className="text-uppercase">
            {" "}
            {t("recruteinscription.section10.title2")}
          </div>
        </div> */}
        {/* <Image src={planet} className="planet-1"></Image> */}
        <div className="roboto-condensed-bold fontSize-90 text-center  text-uppercase yellow-color m-mobile pt-20">
          <div className="text-spacing-5 text-uppercase">
            {t("recruteinscription.section10.text1")}
          </div>
          <div className="text-uppercase pt-20">
            {" "}
            {t("recruteinscription.section10.text2")}
            <Image src={img6} className="animate-3 imgcontact "></Image>

          </div>
        </div>
        <div className="text-center white-color fontSize-30 padding-t m-mobile">
          {t("recruteinscription.section10.description1")}
        </div>
        <RecruterInscrit></RecruterInscrit>
      </section>
      <section className="pg-apropos-sec5 ">
        <div className="etoile-propos"></div>
        <div className="overlay-sections6"></div>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-90 yellow-color float-left text-spacing-7 pt-20 pb-5">
                {t("home.univers")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold ml-big text-uppercase fontSize-90 yellow-color text-spacing-3 infini">
                {t("home.infini")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                {t("home.contact")}
              </p>
            </ScrollAnimation>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceOutLeft"
            >
              <p className="roboto-condensed-bold text-center fontSize-40 mb-5">
                {t("home.adress")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40 mb-5">
                {t("home.phone")}
              </p>
              <p className="roboto-condensed-bold text-center fontSize-40  mb-5">
                {t("home.mail")}
              </p>
              <p className="roboto-condensed text-center fontSize-25 notice text-underline">
                <Link to="/notice">{t("home.mention")}</Link>
              </p>

            </ScrollAnimation>
            <Image src={femme} className=" imgcontact2 "></Image>

          </Col>
        </Row>
      </section>
    </div>
  );
}
