import React from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "../../components/Login/LoginForm";
import Header from "../../components/Header/Header";
import { Image } from "react-bootstrap";
import Astro from "../../assets/img/6.png"
import login from "../../assets/img/1.png"

import './Login.scss'


export default function Login() {
    const { t } = useTranslation();


    return (
        <div className="loginPage ">
            <div className="LoginSection ">
                <Header />
                <div className="container-fluid mt-5 ">
                    <div className="d-lg-flex flex-lg-row flex-md-wrap flex-sm-wrap flex-sm-column  mt-5 pl-3">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6  d-flex flex-column  align-items-center mt-5">
                            <div className="col-md-12 col-lg-10 col-xl-10 col-sm-12  text-justify   mt-4">
                                <p className="astro-text  roboto-meduim text-uppercase yellow-color">{t('login.h1')}</p>
                            </div>
                            <div className="col-md-10 col-lg-8 col-xl-8 col-sm-10  text-justify d-flex flex-column  align-items-center mb-4 mt-1">
                                <p className="astro2-text Roboto-light  text-justify">{t('login.h2')}</p>
                                <Image src={login} alt="loginAstro-img1" height="auto" width="auto" className=" d-none d-lg-block login" />

                            </div>
                            <div className="col-xxl-10 col-xl-10 mt-2">
                                {/* <Image src={Astro} alt="loginAstro-img" height="auto" width="auto" className=" d-none d-lg-block widthimg" /> */}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5 col-xl-6 col-xxl-6 col-sm-12  mt-sm-3 mb-md-5 p-0 ">
                            <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-8 col-sm-12  mt-sm-3 mb-md-5 ">
                                <LoginForm />

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            {/* <div className="overlay-login"></div> */}

        </div>
        
    );
}
