import React, { useContext, useEffect, useState } from "react";
import { Tabs, Tab, Table, Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context as AdministratorContext } from "../../../context/reducers/AdminReducer";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import Paginate from "../../../components/Paginate/Paginate";
import AvatarGroup from "../../../components/avatar/avatarGroup";
import { formatDate, formatTime } from "../../../services/Controller/DateFormatter";
import { getStatus } from "../../../services/Controller/MissionServices";
import "./FollowUpAdmin.scss";
import MissionsFilter from "../../../components/MissionsFilter/MissionsFilter";

/* Condidatures Page */
function FollowUpAdmin() {
  /* Context Api */
  const { getAllMissionsFollowUP, getAllInterviewsFollowUP, hideToast, state } = useContext(AdministratorContext);

  /* use Location */
  const location = useLocation();

  /* History */
  const history = useHistory();

  /* use Translation */
  const { t } = useTranslation();

  /* Condidatures lists */
  const [allMissionsFollow, setAllMissionsFollow] = useState([]);
  const [allInterviewsFollow, setAllInterviewsFollow] = useState([]);
  /* Filter Mission */
  const [resetForm, setResetForm] = useState(false);
  /* Table Content */
  const displayedColumns = [
    "Nom Formateur",
    "Email",
    "Mission",
    "Entreprise",
    "Formateur",
    "Date",
    "Statut",
  ];
  const [currentItems, setCurrentItems] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [displayedInterview, setDisplayedInterview] = useState([]);
  /* Tab Active Value */
  const [defaultTabValue, setDefaultTabValue] = useState("Missions");
  const namePage = location.pathname.split("/")[2];
  /* eslint-disable */
  useEffect(() => {
    getAllMissionsFollowUP();
    getAllInterviewsFollowUP();
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (state.followUpMissions) setAllMissionsFollow(state.followUpMissions);
    setDisplayedData(state.followUpMissions);
    if (state.followUpInterviews) setAllInterviewsFollow(state.followUpInterviews);
    setDisplayedInterview(state.followUpInterviews)
  }, [state]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Content Condidatures List By Type */
  function showFollowUpContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {defaultTabValue === "Missions"
              ? displayedData && displayedData.length ? getListFollow() : null
              : displayedInterview && displayedInterview.length ? getListFollow() : null}
          </tbody>
        </Table>
        {defaultTabValue === "Missions" ? (
          !displayedData || !displayedData.length ? (
            <NothingToShow type="array" />
          ) : (
            <Paginate
              items={allMissionsFollow}
              setCurrentItems={setCurrentItems}
              itemsPerPage={10}
            />
          )
        ) : !displayedInterview || !displayedInterview.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={allInterviewsFollow}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* list Follow UP Interviews Map */
  function getListFollow(array) {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Inspirnaute" + index}>
          <td className="tbody-td-folow">
            <Row className="margin-unset align-items-center">
              <Col id="cadidat-badge" className="margin-unset" lg={2} md={3} xs={3}><AvatarGroup size="md" profils={[el]} /></Col>
              <Col className="margin-unset" lg={8} md={8} xs={8}>
                <div className="title-candidat-name link mb-2" onClick={() => { redirectTo(el, "Profil") }}>
                  {el.name} {el.lastname}
                </div>
              </Col>
            </Row>
          </td>
          <td className="w-150">
            <p>{el.email ? el.email : null}</p>
          </td>
          <td className="w-150 link" onClick={() => { redirectTo(el, "Mission") }}>
            <p>{el.missionTitle ? el.missionTitle : null}</p>
          </td>
          <td className="w-150">
            <p>{el.entreprise ? el.entreprise : null}</p>
          </td>
          <td className="w-150">
            <p>{el.poste ? el.poste : null}</p>
          </td>
          <td className="w-250">
            {defaultTabValue === "Missions"
              ?
              <p>
                Du{" "}
                {el.dateStart && el.dateEnd
                  ? formatDate(el.dateStart) + " Au " + formatDate(el.dateEnd)
                  : " - - - "}
              </p>
              :
              <div>
                {el.selectedDate ? (formatDate(el.selectedDate) +
                  " à " + formatTime(el.selectedDate)
                ) : el.firstDate && el.secondDate ? (
                  <>
                    <div className="date-interview">
                      Le{" "}
                      {formatDate(el.firstDate) +
                        " à " +
                        formatTime(el.firstDate)}{" "}
                    </div>
                    <div className="date-interview">
                      Le{" "}
                      {formatDate(el.secondDate) +
                        " à " +
                        formatTime(el.secondDate)}{" "}
                    </div>
                  </>
                ) : (
                  null
                )}
              </div>
            }
          </td>
          <td className="w-250">
            <label className={"text-tag " + getStatus(el.status)?.class}>
              {getStatus(el.status)?.label}
            </label>
          </td>
        </tr>
      );
    });
  }
  /* redirectTo Formateur Detail */
  function redirectTo(el, type) {
    if (type === "Profil") {
      history.push(t("role.administrator") + t("sidebar.inspirnautes.path") + "/" + el.idUser);
    }
    else {
      history.push(t("role.administrator") + t("sidebar.missions.path") + "/" + el.idMission);
    }
  }
  /* Tabulation onChange */
  function changeTabulation(tab) {
    setDefaultTabValue(tab);
  }
  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />

      {/* Follow Up Content   */}
      <div id="main-without-header" className="main-without-header">
        <Row className="align-md">
          <Col lg={3} md={12} xs={12} className="w-md-100">
            <label className="title-page margin-bottom-follow ">Follow UP</label>
          </Col>
          {defaultTabValue === "Missions" ? <Col lg={9} md={12} xs={12} className="w-md-100">
            <MissionsFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allMissionsFollow}
              setDisplayedData={setDisplayedData}
              namePage={namePage}
            />
          </Col> : <Col lg={9} md={12} xs={12} className="w-md-100">
            <MissionsFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allInterviewsFollow}
              setDisplayedData={setDisplayedInterview}
              namePage={"follow-intrviews"}
            />
          </Col>}
        </Row>
        <Tabs activeKey={defaultTabValue} onSelect={(k) => changeTabulation(k)} defaultActiveKey="Recruiters" className="mt-3 mb-3 w-100">
          <Tab eventKey="Missions" title={"Missions (" + (displayedData ? displayedData.length : 0) + ")"}>
            {showFollowUpContent()}
          </Tab>
          <Tab eventKey="Interviews" title={"Entretiens (" + (displayedInterview ? displayedInterview.length : 0) + ")"}>
            {showFollowUpContent()}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

/* Export Candidatures component */
export default FollowUpAdmin;
