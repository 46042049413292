import { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { DocumentPersonnel } from "../../../components/documents/DocumentPersonnel";
import ToastService from "../../../components/Toast/ToastService";
import { Context as DocumentContext } from "../../../context/reducers/DocumentReducer";
import { useHistory, useLocation } from "react-router-dom";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import Paginate from "../../../components/Paginate/Paginate";
import "./DocumentUser.scss";
import {
  getUserId,
  isAdmin,
  isCosmonaute,
} from "../../../services/Controller/CurrentUserServices";
function DocumentUser() {
  const {
    state,
    getAllMissions,
    ediDocument,
    getDocument,
    addDocument,
    dumpDocument,
    hideToast,
  } = useContext(DocumentContext);
  const reload = () => window.location.reload();
  const [modelShow, setModelShow] = useState(false);
  const [listePersonnel, setListePersonnel] = useState(null);
  const [listeMission, setListeMission] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  /* use History */
  const history = useHistory();
  /* use Location */
  const location = useLocation();
  const idUsuer = location.pathname.split("/")[3];
  const someEventHandler = (id) => {
    if (isCosmonaute()) {
      history.push("/user/mission/" + id);
    } else if (isAdmin()) {
      history.push("/admin/user/" + idUsuer + "/mission/" + id);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    if (isCosmonaute()) {
      getAllMissions(getUserId());
      getDocument(getUserId());
    } else {
      const idMission = location.pathname.split("/")[3];
      getDocument(idMission);
      getAllMissions(idMission);
    }
  }, []);
  /* eslint-enable */
  useEffect(() => {
    if (state.getDocuments?.length > 0) setListePersonnel(state.getDocuments);
    setListeMission(state.allMission);
  }, [state]);

  /* refresh list docs */
  useEffect(() => {
    if (state.document) {
      let id = getUserId();
      getDocument(id);
      dumpDocument()
    }
  }, [state.document, getDocument, dumpDocument]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* list Recruiter Map */
  function getListRecruiter() {
    return currentItems.map((el, index) => {
      return (
        <tr
          className="table-content table-padding line-height-document"
          key={"Recruiter" + index}
        >
          <td className="text-font-td pt-4">{el ? el.title : "..."}</td>
          <td className="pt-4">{el ? el.nbdocs : "..."}</td>
          <td className="pt-3">
            {" "}
            <div className="text-center pointer d-flex">
              {/* Tooltip */}
              <ReactTooltip id="Consult" place="bottom">
                {" "}
                Consulter{" "}
              </ReactTooltip>
              <span
                className="btn-detail blue"
                onClick={() => {
                  someEventHandler(el.id);
                }}
              >
                <FaEye data-tip data-for="Consult" />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }
  return (
    <>
      <ToastService data={state.toastContent} />
      <DocumentPersonnel
        modelShow={modelShow}
        setModelShow={setModelShow}
        addDocument={addDocument}
        edit={listePersonnel}
        ediDocument={ediDocument}
        reload={reload}
      />
      <div id="header-Content" className="header-Content"></div>
      <div id="main" className="main">
        <div className="title-page">Mes Documents par Mission</div>
        <Row lg={12} md={12} xs={12} className="display-row-admin mt-5">
          <Col lg={6} md={6} sm={12} className="border-Col-document">
            <div className="text-mission-document color-2 margin-buttom-col-candidat">
              Documents par mission
            </div>
            <Table className="table user-table " >
              <thead>
                <tr>
                  <th className="text-tr-table">Mission</th>
                  <th>Nombre de documents</th>
                  <th>Détail mission</th>
                </tr>
              </thead>
              <tbody>
                {listeMission && listeMission.length
                  ? getListRecruiter()
                  : null}
              </tbody>
            </Table>
            {!listeMission || !listeMission.length ? (
              <NothingToShow type="array" />
            ) : (
              <Paginate
                items={listeMission}
                setCurrentItems={setCurrentItems}
                itemsPerPage={4}
              />
            )}
          </Col>
          <Col
            lg={6}
            md={6}
            xs={12}
            className="border-Col-document margin-left-col1"
          >
            <div id="doc-col">
              <div className="text-mission-document color-1 margin-bottom-th">
                Documents personnels
              </div>
              {isCosmonaute() ? (
                <Button
                  className="border-button float-right"
                  onClick={() => {
                    setModelShow(true);
                  }}
                >
                  {listePersonnel ? "Modifier" : "Ajouter"}
                </Button>
              ) : null}

              <Table className="table user-table" responsive="xl" >
                <thead>
                  <tr>
                    <th>Document</th>
                    <th>Date</th>
                    <th>Taille</th>
                  </tr>
                </thead>
                <tbody>
                  {listePersonnel && listePersonnel.length ? (
                    <>
                      {listePersonnel.slice(1, -1).map((item, index) => (
                        <tr key={index}>
                          <td className="text-font-td pt-4 pb-4">
                            <a
                              target="_blank"
                              rel="noreferrer noopener"
                              media="true"
                              href={item.doc_url}
                            >
                              {item ? item.doc_name : "..."}
                            </a>
                          </td>
                          <td className="pt-4 pb-4">{item ? (item.date_updat):'...'}</td>
                          <td className="pt-4 pb-4">
                            {item ? item.doc_size + " ko" : "..."}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : null}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default DocumentUser;
