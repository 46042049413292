import React, { useContext, useEffect, useState } from "react";
import "./MissionCosmomaute.scss";
import { Row, Col, Card, Tab, Tabs, Dropdown } from "react-bootstrap";
import MissionsFilter from "../../../components/MissionsFilter/MissionsFilter";
import { Context as MissionCosmonaute } from "../../../context/reducers/CosmonauteReducer";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import { FaMapMarkerAlt, FaRegCalendarMinus } from "react-icons/fa";
import { formatDate, getDuration } from "../../../services/Controller/DateFormatter";
import Paginate from "../../../components/Paginate/Paginate";
import { fr } from "date-fns/locale";
import { getStatusMissionCosmo } from "../../../services/Controller/MissionServices";
import { ModalMissionCausmonaute } from "../../../components/modalCosmonaute/ModalMissionCosmonaute";
import { useHistory } from "react-router-dom";
import { ContactAdmin } from "../../../components/ModalContact/ContactAdmin";
import ToastService from "../../../components/Toast/ToastService";
import { useTranslation } from "react-i18next";
import { MissionFavorite } from "../../../components/MissionFavorite/MissionFavorite";
import { format } from "date-fns";

export default function MissionCosmomaute() {
  /* use History */
  const history = useHistory();
  /* Translation */
  const { t } = useTranslation();
  /* Context Api */
  const {
    getAllMissions,
    CurrentMissions,
    declineMission,
    dumpMission,
    hideToast,
    contactAdmin,
    postMissionFavorite,
    getMissionFavorite,
    state,
  } = useContext(MissionCosmonaute);
  const [resetForm, setResetForm] = useState(false);
  const [currentMission, setCurrentMission] = useState(null);
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [modalContact, setModalContact] = useState(false);
  const [defaultTabValue, setDefaultTabValue] = useState("All");
  const [allMissions, setAllMissions] = useState({
    All: [],
    preselected: [],
    recruited: [],
    declined: [],
    interrupted: [],
  });
  
  const [modalMissionShow, setModalMissionShow] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalFavorite, setModalFavorite] = useState(false);
  const [missionFavoriteData, setMissionFavoriteData] = useState(null);
  const [detail, setDetail] = useState(null);
  /*eslint-disable */
  //get data
  useEffect(() => {
    if (history.location.state) setDefaultTabValue(history.location.state);
    getAllMissions();
    CurrentMissions();
    getMissionFavorite();
  }, []);

  /*eslint-enable */
  useEffect(() => {
    if (state.allMissions) {
      let list = state.allMissions;
      list?.map((el) => (el.missionTag = getStatusMissionCosmo(el)));
      const allData = {
        All: list,
        preselected: list.filter((el) => el.missionTag?.label === "Proposée"),
        recruited: list.filter((el) => el.missionTag?.label === "Assignée"),
        interrupted: list.filter((el) => el.missionTag?.label === "Interrompue"),
        declined: list.filter((el) => el.missionTag?.label === "Refusée"),
        past: list.filter((el) => el.missionTag?.label === "Terminée"),
      };
      setAllMissions(allData);
      setDisplayedData(allData[defaultTabValue]);

      setCurrentMission(state.CurrentMission);
    }
    setDetail(state.detailDashboard);
    setMissionFavoriteData(state.missionFavoriteData);
  }, [state, defaultTabValue]);
  /* refresh after change | Reject Mission */
  useEffect(() => {
    if (state.missionCosmonaute) {
      getAllMissions();
      dumpMission();
    }
  }, [dumpMission, getAllMissions, state.missionCosmonaute]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);

  function openModalInterview(mission, title, type) {
    setTitleModal(title);
    setSelectedItem(mission);
    setTypeModal(type);
    setModalMissionShow(true);
  }

  function openModalContact(mission) {
    setSelectedItem(mission);
    setModalContact(true);
  }

  /* Change Tab */
  function onSelectTab(tab) {
    setResetForm(true);
    if (allMissions[tab] && allMissions[tab].length)
      setDisplayedData(allMissions[tab]);
    else setDisplayedData([]);
    setDefaultTabValue(tab);
  }

  /* Détail Mission */
  function redirectToMission(id) {
    history.push(t("role.user") + t("sidebar.missions.path") + "/" + id);
  }

  // Header Cosmonaute
  function MissionCosmonauteHeader() {
    return (
      <Row className="mt-4">
        <Col lg={6} md={12} xs={12} className="pr-form mb-sm-3">
          <Card className="card-cosmonaute p-1">
            <Card.Header>
              <div className="d-inline-text mr-20 current-mision-header">
                Mes formations actuelles
              </div>
              {/* <div className="vert_title d-inline-text">
                (vous avez{" "}
                {currentMission ? currentMission?.restMonths + " " : " 0 "}
                mois restants)
                <i className="icon-see-more float-right"></i>
              </div> */}
            </Card.Header>

            <Card.Body>
              <div className="d-flex">
                <span className="cardTitle mr-20">
                  {currentMission && currentMission.mission
                    ? currentMission.mission.title
                    : "......"}
                </span>
                {currentMission?.mission.dateStart
                      ?
                      <span className="month-white">
                  <small> {getDuration(
                        currentMission?.mission.dateStart,
                        currentMission?.mission.dateEnd
                      )}
                  </small>
                </span>
                      : null}
              </div>
              <p className="company color-company mt-2">
                {currentMission && currentMission.mission
                  ? currentMission.mission.companyName
                  : "....."}
              </p>
              <div className="text-detail d-flex">
                <FaRegCalendarMinus />
                <span className="ml-10 mr-20">
                  <p>
                    Date debut :{" "}
                    {currentMission && currentMission.mission
                      ? formatDate(currentMission.mission.dateStart, fr)
                      : "...."}{" "}
                    - Date fin :{" "}
                    {currentMission && currentMission.mission
                      ? formatDate(currentMission.mission.dateEnd, fr)
                      : "...."}
                  </p>
                </span>
              </div>
              <div>
                <div className="text-detail  d-flex">
                  <FaMapMarkerAlt />
                  <span className="ml-10 mr-20">
                    <p>
                      Place :{" "}
                      {currentMission && currentMission.mission
                        ? currentMission.mission.place
                        : "...."}
                    </p>
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/*  */}
        <Col className="pr-form mb-sm-3 margin-top-block" lg={3} md={6} xs={12}>
          <Card className="p-3 card-dashboard-cosmonaute">
            <Card.Body className="d-flex">
              <div className="text-resume">
                <span className="mr-5 line-height-mission">
                  {allMissions && allMissions.preselected
                    ? allMissions.preselected.length
                    : 0}
                </span>
                Formations En Attente
              </div>
            </Card.Body>
          </Card>
          <Card className="card-dashboard-cosmonaute p-3 mt-3">
            <Card.Body className="d-flex">
              <div className="text-resume">
                <span className="mr-4 line-height-mission">
                  {allMissions && allMissions.past
                    ? allMissions?.past.length
                    : 0}
                </span>{" "}
                Formations Terminées
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/*  */}
        <Col lg={3} md={6} xs={12} className="mb-sm-3 margin-top-block-mission">
          <Card className="specific_mission w-100 h-100">
            <Card.Body>
              <span className="mission-text">
              Vous souhaitez ajouter une nouvelle formation{" "}
              </span>
              <Row>
                <Col lg={8} md={8} xs={12}>
                  <span className="petit-text">
                    
                  </span>
                </Col>
                <Col lg={4} md={4} xs={12} className="display-mobile-logo">
                  <i className="icon-ask-admin icon-mission-size float-right"></i>
                </Col>
              </Row>

              <div className="mt-lg-2 mt-xl-4">
                <div onClick={() => { setModalFavorite(true);}} className="text-fill-form roboto-italic">
                remplissez ce formulaire{" "}
                  <i id="arrow-icon" className="icon-arrow-left link-decoration" onClick={() => { setModalFavorite(true);}}></i>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  /* Interview Cosmonaute Main */
  function showMissionContent() {
    return (
      <>
        <div className="d-flex flex-lg-row flex-wrap flex-md-column flex-sm-column col-12">
          {currentItems.map((item, key) => {
            return (
              <div className={ "col-lg-6 col-md-12 col-sm-12 " + (key % 2 ? "pl-form" : "pr-form")}
                key={item.id}
              >
                <Card className="card-content mb-45 mr-2">
                  <Card.Body>
                    <Row className="mb-">
                      <Col lg={10} md={10} xs={10}>
                        <label className="text-header-cosmonaute mr-4 link" onClick={() => { redirectToMission(item.id); }}>
                          {item.title}
                        </label>
                        {item.status ? (
                          <label className={"text-tag " + item.missionTag?.class}>
                            {item.missionTag?.label}
                          </label>
                        ) : null}
                      </Col>
                      <Col lg={2} md={2} xs={2}>
                        <Dropdown className="float-right" align="end">
                          <Dropdown.Toggle id="dropdown-basic">
                            <i className="icon-see-more"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                            item.missionTag?.label === "Proposée" || item.missionTag?.label === "Assignée" ? (
                              <Dropdown.Item className="text-calendar" as="button" onClick={() => openModalInterview( item, "Rejeter mission", "decline")}>
                                Rejeter mission
                              </Dropdown.Item>
                            ) 
                            : null}
                            <Dropdown.Item className="text-calendar" as="button" onClick={() => openModalContact(item)}>
                              Contact administrateur
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row>
                      <p className="company">
                        {item.companyName ? item.companyName : "..."}
                      </p>
                    </Row>
                    <Row>
                      <div className="text-detail d-flex">
                        <FaRegCalendarMinus />
                        <span className="ml-10 mr-20">
                          <p>
                            Du {format(new Date(item.dateStart), "dd/MM/yyyy") + " "} Au {" "}
                            {format(new Date(item.dateEnd), "dd/MM/yyyy")}
                          </p>
                        </span>
                      </div>
                      <div className="text-detail  d-flex">
                        <FaMapMarkerAlt />
                        <span className="ml-10 mr-20">
                          <p>{item.place ? item.place : "..."}</p>
                        </span>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
        <Paginate
          items={displayedData}
          setCurrentItems={setCurrentItems}
          itemsPerPage={10}
        />
      </>
    );
  }

  return (
    <>
      <ToastService data={state.toastContent} />
      <MissionFavorite
        modalFavorite={modalFavorite}
        setModalFavorite={setModalFavorite}
        postMissionFavorite={postMissionFavorite}
        detail={detail}
        missionFavoriteData={missionFavoriteData}
      />
      <ModalMissionCausmonaute
        modalMission={modalMissionShow}
        type={typeModal}
        mission={selectedItem}
        setModalMission={setModalMissionShow}
        title={titleModal}
        declineMission={declineMission}
      />
      <ContactAdmin
        setModalContact={setModalContact}
        modalContact={modalContact}
        contactAdmin={contactAdmin}
        mission={selectedItem}
      />
      <div id="header-Content" className="header-Content">
        {MissionCosmonauteHeader()}
      </div>
      <div id="main" className="main">
        <Row className="align-items-baseline align-md">
          <Col lg={3} md={12} xs={12} className="w-md-10 mb-2">
            <label className="title-page">Mes Missions et Formations</label>
          </Col>
          <Col lg={9} md={12} xs={12} className="w-md-100">
            <MissionsFilter
              resetForm={resetForm} setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allMissions}
              setDisplayedData={setDisplayedData}
            />
          </Col>
        </Row>
      
        <Tabs defaultActiveKey="All" activeKey={defaultTabValue} onSelect={(k) => onSelectTab(k)} className="mb-3 w-100">
          <Tab eventKey="All"
            title={"Tous (" + (allMissions && allMissions.All ? allMissions.All.length : 0) + ")"}>
            {!displayedData || !displayedData.length ? <NothingToShow /> : showMissionContent()}
          </Tab>
          <Tab eventKey="preselected"
            title={"Proposées (" + (allMissions && allMissions.preselected ? allMissions.preselected.length : 0) + ")"}>
            {!displayedData || !displayedData.length ? <NothingToShow /> : showMissionContent()}
          </Tab>
          <Tab eventKey="recruited"
            title={"Assignées (" + (allMissions && allMissions.recruited ? allMissions.recruited.length : 0) + ")"}>
            {!displayedData || !displayedData.length ? <NothingToShow /> : showMissionContent()}
          </Tab>
          <Tab eventKey="declined"
            title={"Refusées (" + (allMissions && allMissions.declined ? allMissions?.declined.length : 0) + ")"}>
            {!displayedData || !displayedData.length ? <NothingToShow /> : showMissionContent()}
          </Tab>
          <Tab eventKey="past"
            title={"Terminées (" + (allMissions && allMissions.past ? allMissions?.past.length : 0) + ")"}>
            {!displayedData || !displayedData.length ? <NothingToShow /> : showMissionContent()}
          </Tab>
          <Tab eventKey="interrupted"
            title={"Interrompues (" + (allMissions && allMissions.interrupted ? allMissions.interrupted.length : 0) + ")"}>
            {!displayedData || !displayedData.length ? <NothingToShow /> : showMissionContent()}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
