import {
  API_HOST,
  API_ADD_COSMONAUTE_DOCUMENT,
  API_GET_COSMONAUTE_DOCUMENT,
  API_PROFIL_DETAILS,
  API_EDIT_COSMONAUTE_DOCUMENT,
  API_ADD_CONTRACT,
  API_ADD_CRA,
  API_GET_CONTRACT,
  API_MISSION_RECRUTED,
  API_DOCUMENT_RECRUTER,
  API_RECRUTED_CANDIDAT,
  API_ONE_MISSION_ADMINISTRATOR,
  API_RESEND_CONTRAT_RECRUITER,
  API_DETAIL_MISSION_RECRUTER,
  API_RECRUITER_ADMIN,
  API_SEND_MAIL_CRA_CONTRAT,
  API_SEND_MAIL_VOICES,
  API_SEND_VOICES_ADMIN,
} from "../../Api";
import { SHOW_TOAST } from "../constants/Toast";
import {
  ADD_CONTRACT,
  ADD_COSMONAUTE_DOCUMENT,
  ADD_CRA,
  DETAIL_MISSION_RECRUTER,
  DETAIL_MISSION_RECRUTER_ADMIN,
  EDIT_COSMONAUTE_DOCUMENT,
  ENVOIE_EMAIL_CONTRAT_CANDIDAT,
  GET_CONTRACT,
  GET_COSMONAUTE_DOCUMENT,
  GET_RECRUTED_CANDIDAT,
  MISSION_RECRUITER_ADMIN,
  RECRUTED_MISSION_CANDIDAT,
  RESEND_CONTRAT_RECRUITER,
  RETRIEVE_COSMONAUTE,
  SEND_EMAIL_CONTRAT_RECRUITER,
  SEND_VOICES_ADMIN,
  DUMP_COSMONAUTE_DOCUMENT,
  DUMP_GET_CRA
} from "../constants/Cosmonaute";
import axiosServices from "../../services/Axios/axiosServices";
import { getUserId } from "../../services/Controller/CurrentUserServices";
import { GET_RECRUTER_DOCUMENT } from "../constants/Recruiter";
import { ONE_MISSION_ADMIN } from "../constants/Administrator";

/* Add document */
export const addDocument = (dispatch) => (body) => {
  // URL add document
  const URL = API_HOST + API_ADD_COSMONAUTE_DOCUMENT;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: ADD_COSMONAUTE_DOCUMENT,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "documents sont ajouté avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'ajout documents" },
      });
    }
  );
};
/* Add document */
export const ediDocument = (dispatch) => (body, id) => {
  // URL add document
  const URL = API_HOST + API_EDIT_COSMONAUTE_DOCUMENT + id;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: EDIT_COSMONAUTE_DOCUMENT,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "documents sont edité avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'édit documents" },
      });
    }
  );
};

/* dump document */
export const dumpDocument = (dispatch) => () => {
  dispatch({
    type: DUMP_COSMONAUTE_DOCUMENT,
    payload: null,
    toastContent: null,
  });
};
/* dump CRA */
export const dumpCra = (dispatch) => () => {
  dispatch({
    type: DUMP_GET_CRA,
    payload: null,
    toastContent: null,
  });
};
/* GET document */
export const getDocument = (dispatch) => (id) => {
  // URL add document
  const URL = API_HOST + API_GET_COSMONAUTE_DOCUMENT + id;
  // Call API document
  axiosServices.get(URL).then(
    (res) => {
      dispatch({
        type: GET_COSMONAUTE_DOCUMENT,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "documents sont recupéreés avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
        },
      });
    }
  );
};
export const getAllMissions = (dispatch) => async (id) => {
  const url = API_HOST + API_MISSION_RECRUTED + id;
  await axiosServices.get(url).then(
    (resp) => {
      dispatch({
        type: RECRUTED_MISSION_CANDIDAT,
        payload: resp.data,
        toastContent: {
          status: "success",
          body: "récupération des missions avec succées",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération des missions",
        },
      });
    }
  );
};
/* get detail profil candidate */
export const ProfilCosmonaute = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_PROFIL_DETAILS + getUserId();
  axiosServices.get(urlCosmonaute).then(
    (profil) => {
      dispatch({
        type: RETRIEVE_COSMONAUTE,
        payload: profil.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du détail profil",
        },
      });
    }
  );
};
/* Add contract */
export const addContract = (dispatch) => (body) => {
  // URL add document
  const URL = API_HOST + API_ADD_CONTRACT;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: ADD_CONTRACT,
        payload: res.data,
        toastContent: { status: "success", body: "Contat ajouté avec succès" },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'ajout contat" },
      });
    }
  );
};
/* Add contract */
export const addCra = (dispatch) => (body, id) => {
  // URL add document
  const URL = API_HOST + API_ADD_CRA + id;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: ADD_CRA,
        payload: res.data,
        toastContent: { status: "success", body: "cra ajouté avec succès" },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'ajout cra" },
      });
    }
  );
};
/* get contract */
export const getContract = (dispatch) => async (id, idUser) => {
  const urlCosmonaute = API_HOST + API_GET_CONTRACT + idUser + "/" + id;
  axiosServices.get(urlCosmonaute).then(
    (res) => {
      dispatch({
        type: GET_CONTRACT,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "récupération Contrat avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du contract",
        },
      });
    }
  );
};
/* GET Mission Document for Recruter */
export const getDocumentRecruter = (dispatch) => async (id) => {
  const urlCosmonaute = API_HOST + API_DOCUMENT_RECRUTER + id;
  axiosServices.get(urlCosmonaute).then(
    (res) => {
      dispatch({
        type: GET_RECRUTER_DOCUMENT,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "récupération Mission avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du Mission",
        },
      });
    }
  );
};
/* GET Mission Document for Recruter */
export const getAllCandidatRecruted = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_RECRUTED_CANDIDAT;
  axiosServices.get(urlCosmonaute).then(
    (res) => {
      dispatch({
        type: GET_RECRUTED_CANDIDAT,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "récupération Candidat avec succées",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du Candidat",
        },
      });
    }
  );
};
/* GET Recruter for ADMIN*/
export const getAllRecrutedRecruiter = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_RECRUITER_ADMIN;
  axiosServices.get(urlCosmonaute).then(
    (res) => {
      dispatch({
        type: MISSION_RECRUITER_ADMIN,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "récupération Entreprise  avec succées",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du Entreprise ",
        },
      });
    }
  );
};
/* Get one Mission for Admin */
export const getOneMission = (dispatch) => (id) => {
  // URL get one Mission for Admin
  const URL = API_HOST + API_ONE_MISSION_ADMINISTRATOR + id;
  // Call API one Mission for Admin
  axiosServices.get(URL).then(
    (res) => {
      dispatch({
        type: ONE_MISSION_ADMIN,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération de la Mission choisie",
        },
      });
    }
  );
};
/* Add contract */
export const importContratRecruiter = (dispatch) => (body) => {
  // URL add document
  const URL = API_HOST + API_RESEND_CONTRAT_RECRUITER;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: RESEND_CONTRAT_RECRUITER,
        payload: res.data,
        toastContent: { status: "success", body: "contrat ajouté avec succès" },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'ajout contrat" },
      });
    }
  );
};
/* Get one Mission for recruiter */
export const getMissionDetail = (dispatch) => (id) => {
  // URL get one Mission for Admin
  const URL = API_HOST + API_DETAIL_MISSION_RECRUTER + id;
  // Call API one Mission for Admin
  axiosServices.get(URL).then(
    (res) => {
      dispatch({
        type: DETAIL_MISSION_RECRUTER,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération de mission",
        },
      });
    }
  );
};
/* Get Mission for recruiter from page admin */
export const getMissionDetailAdmin = (dispatch) => (id, body) => {
  // URL get one Mission for Admin
  const URL =
    API_HOST + API_DETAIL_MISSION_RECRUTER + id + "?idRecruiter=" + body;
  // Call API one Mission for Admin
  axiosServices.get(URL).then(
    (res) => {
      dispatch({
        type: DETAIL_MISSION_RECRUTER_ADMIN,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération de mission",
        },
      });
    }
  );
};
/* send mail to candidat cra contrat */
export const envoieMailCraContrat = (dispatch) => (body) => {
  // URL add document
  const URL = API_HOST + API_SEND_MAIL_CRA_CONTRAT;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: ENVOIE_EMAIL_CONTRAT_CANDIDAT,
        payload: res.data,
        toastContent: { status: "success", body: "Email envoyé avec succès" },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'envoie email" },
      });
    }
  );
};
/* send mail to recruiter facture contrat */
export const envoieMailInvoiceContrat = (dispatch) => (body) => {
  // URL add document
  const URL = API_HOST + API_SEND_MAIL_VOICES;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: SEND_EMAIL_CONTRAT_RECRUITER,
        payload: res.data,
        toastContent: { status: "success", body: "Email envoyé avec succès" },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'envoie email" },
      });
    }
  );
};
export const sendInvoices = (dispatch) => (body, savedContratId) => {
  // URL add document
  const URL = API_HOST + API_SEND_VOICES_ADMIN + savedContratId;
  // Call API document
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: SEND_VOICES_ADMIN,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "Factures envoyées avec succès",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'envoie facture" },
      });
    }
  );
};
