import React, { useContext, useEffect, useState } from "react";
import "./DashboardAdmin.scss";
import { Context as Dashboardcontext } from "../../../context/reducers/AdminReducer";
import { Card, Col, Image, Row, Table } from "react-bootstrap";
import { Line, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import astro from "../../../assets/img/1.png";
// import astro2 from "../../../assets/img/1.png";

Chart.register(...registerables);
/* Dashboard Content */
function DashboardAdmin() {
  const {
    getUsersStats,
    getMissionStats,
    getMissionByDay,
    getCandidateByDay,
    getTopRecruiter,
    state,
  } = useContext(Dashboardcontext);
  const [usersStats, setUsersStats] = useState(null);
  const [missionStats, setMissionStats] = useState(null);
  const [missionByDay, setMissionByDay] = useState(null);
  const [candidateByDay, setCandidateByDay] = useState(null);
  const [topRecruiter, setTopRecruiter] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [candidats, setCandidats] = useState(null);
  const [doughnutData, setDoughnutData] = useState(null);
  //capitalize first letter
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  /* eslint-disable */
  useEffect(() => {
    getUsersStats();
    getMissionStats();
    getMissionByDay();
    getCandidateByDay();
    getTopRecruiter();
  }, []);
  /* eslint-enable */
  useEffect(() => {
    if (state) {
      setUsersStats(state.UsersStats);
      setMissionStats(state.MissionStats);
      setMissionByDay(state.MissionByDay);
      setCandidateByDay(state.CondidateByDay);
      setTopRecruiter(state.TopRecruiter);
    }
  }, [state]);
  /* eslint-disable */
  useEffect(() => {
    if (missionByDay) {
      let labels = [];
      let data = [];
      missionByDay?.map((el) => labels.push(el.date));
      missionByDay?.map((el) => data.push(el.missions));
      setLineData({
        labels: labels,
        datasets: [
          {
            label: "Mission",
            fill: true,
            lineTension: 0,
            backgroundColor: "#2F84BA",
            borderColor: "rgb(166,166,166)",
            borderWidth: 2,
            data: data,

          },
        ],
      });
    }
    if (candidateByDay) {
      let labels = [];
      let data = [];
      candidateByDay?.map((el) => labels.push(el.date));
      candidateByDay?.map((el) => data.push(el.candidats));
      setCandidats({
        labels: labels,
        datasets: [
          {
            label: "Candidats",
            fill: true,
            lineTension: 0,
            backgroundColor: "#2DBEBD",
            borderColor: "rgb(166,166,166)",
            borderWidth: 2,
            data: data,
          },
        ],
      });
    }
    if (missionStats) {
      let labels = [];
      let data = [];
      for (const [key, value] of Object.entries(missionStats)) {
        data.push(value);
        labels.push(capitalizeFirstLetter(key));
      }
      const item = {
        labels: labels,
        datasets: [
          {
            maintainAspectRatio: false,
            responsive: true,
            backgroundColor: ["#2F84BA", "#2DBEBD", "#4653DD", "#1A2A8B"],
            hoverOffset: 4,
            data: data,
          },
        ],
      };
      setDoughnutData(item);
    }
  }, [missionByDay, candidateByDay, missionStats]);
  /* eslint-enable */
  /* Dashboard Header */
  function ShowDashboardHeader() {
    return (
      <>
        <Row
          className="align-items-center margin-header-admin"
          lg={12}
          sm={12}
          md={12}
        >
          <Col className="pl-form pr-form" lg={6} md={12} xs={12}>
            <Col
              lg={12}
              md={12}
              xs={12}
              className="d-flex align-items-center cards-dashborad p-2"
            >
              <Col className="d-flex flex-column" xl={7} lg={7} md={12} xs={12}>
                <p className="text-title-header">Bonjour !</p>
                <p className="text-header-dashboard text-white">
                Vérifiez les derniers progrès des formateurs et des missions 
                </p>
              </Col>
              <Col xl={5} lg={5} md={12} xs={12} className="text-center">
                {/* <Image className="width-100" src={astro} /> */}
                {/* <Image className="width-100" src={astro2} /> */}

              </Col>
            </Col>
          </Col>
          <Col
            lg={6}
            md={12}
            xs={12}
            className="d-flex align-items-center justify-content-center d-flex-blcok-candisates d-flex-warp-header-admin"
          >
            <Col
              lg={8}
              md={12}
              xs={12}
              className="pl-form pr-form margin-div1 col-12-dashbord-detail"
            >
              <Col
                lg={12}
                md={12}
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <Card className="card-dashboard">
                  <Card.Body className="d-flex flex-row justify-content-center align-items-center">
                    <p className="text-number-cosmo mb-1 yellow-color2">
                      {usersStats?.candidats ? usersStats.candidats : 0}
                    </p>
                    <span className="text-cosmo mb-1 yellow-color2">Formateurs ajoutés</span>
                  </Card.Body>
                </Card>
              </Col>
            </Col>
            <Col
              lg={8}
              md={12}
              xs={12}
              className="pl-form pr-form col-12-dashbord-detail-right"
            >
              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex align-items-center justify-content-center padding-block-add-header"
              >
                <Card className="card-dashboard">
                  <Card.Body className="d-flex flex-row justify-content-center align-items-center">
                    <p className="text-number-cosmo mb-1 yellow-color2">
                      {usersStats?.recruteurs ? usersStats.recruteurs  : 0}
                    </p>
                    <span className="text-cosmo mb-1 yellow-color2">Entreprises ajoutées </span>
                  </Card.Body>
                </Card>
              </Col>
            </Col>
          </Col>
        </Row>
      </>
    );
  }
  /* Dashboard Content */
  function ShowDashboardContent() {
    return (
      <>
        <Row className="p-2 mt-3">
          <Row className="d-flex flex-lg-row flex-sm-column flex-md-column">
            <Col
              xl={5}
              lg={5}
              md={12}
              sm={12}
              className="card-container mb-3 d-flex flex-column"
            >
              <div className="text-card text-navy roboto-bold col-12 p-2 ">
                <p className="mt-4 float-left">
                  Les entreprises les plus actives
                </p>
              </div>
              <Table hover responsive borderless className="dashboard-table">
                <thead>
                  <tr className="header-text">
                    <th colSpan={4}>Entreprise</th>
                    <th>Nombre de missions</th>
                    <th>Pays</th>
                  </tr>
                </thead>
                <tbody>
                  {topRecruiter?.map((el, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan={4} className=" ">
                          <div className="d-flex  col-12 ">
                            <p className="col-2 body-text key-color">
                              {index + 1}
                            </p>
                            <p className="col-8 body-text">{el.companyName}</p>
                          </div>
                        </td>
                        <td className="body-text">{el.missions}</td>
                        <td className="body-text">{el.country}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              className="card-container d-flex flex-column align-items-center"
            >
              <div className="text-card text-navy roboto-bold col-12 p-2">
                <p className="mt-4 float-left">Statistiques des missions</p>
              </div>
              <div className="card-Doughnut d-flex align-items-center justify-content-center">
                {doughnutData ? (
                  <Doughnut
                    data={doughnutData}
                    options={{
                      title: {
                        display: true,
                        text: "Missions stats",
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: true,
                          position: "right",
                          labels: {
                            usePointStyle: true,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-3 d-flex flex-lg-row flex-sm-column flex-md-column">
            <Col
              xl={5}
              lg={5}
              md={12}
              sm={12}
              className="card-container-line mb-3 d-flex flex-column"
            >
              <div className="text-card text-navy roboto-bold col-12  mb-3 p-2">
                <p className="mt-4 float-left">Missions ajoutées par mois</p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                {lineData ? (
                  <Line
                    data={lineData}
                    width={"200px"}
                    height={"100px"}
                    options={{
                      scales: {
                        yAxis: {
                          // The axis for this scale is determined from the first letter of the id as `'x'`
                          // It is recommended to specify `position` and / or `axis` explicitly.
                          min:0
                        }
                      },
                      title: {
                        display: true,
                        text: "Nouvelle missions par mois",
                        fontSize: 20,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                ) : null}
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              className="card-container-line mb-md-4 mb-sm-5 d-flex flex-column"
            >
              <div className="text-card text-navy roboto-bold col-12  mb-3 p-2">
                <p className="mt-4 float-left">Candidats ajoutés par mois</p>
              </div>
              <div className="  d-flex align-items-center justify-content-center">
                {candidats ? (
                  <Line
                    data={candidats}
                    width={"200px"}
                    height={"80px"}
                    options={{
                      scales: {
                        yAxis: {
                          interval:10,
                         min:0
                        }
                      },
                      responsive: true,
                      title: {
                        display: true,
                        text: "Nouvelle candidats par mois",
                        fontSize: 20,
                      },

                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Row>
      </>
    );
  }
  /* Main Function */
  return (
    <>
      <div id="header-Content" className="header-Content">
        {ShowDashboardHeader()}
      </div>
      <div className="container-fluid">{ShowDashboardContent()}</div>
    </>
  );
}
/* Export Profil component */
export default DashboardAdmin;
