import React, { useContext, useState, useEffect } from "react";
import { Button, Row, Col, Image, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Context as CosmonauteContext } from "../../../../context/reducers/CosmonauteReducer";
import ToastService from "../../../../components/Toast/ToastService";
import ModalUploadLogo from "../../../../components/ModalUploadLogo/ModalUploadLogo";
import ExperienceCandidate from "./Experience/ExperienceCosmo";
import EducationCandidate from "./Education/EducationCosmo";
import SkillsCandidate from "./Skills/SkillsCosmo";
import LanguesCandidate from "./Langues/LangueCosmo";
import { isAdmin } from "../../../../services/Controller/CurrentUserServices";
import astro from "../../../../assets/img/candidat.png";
import { ErrorFeild, ErrorClass } from "../../../../services/Controller/ValidationForm";
import { emailPattern, phonePattern, numberPattern } from "../../../../services/Controller/PattenService";
import { onFocusPhoneNumber, onBlurPhoneNumber } from "../../../../services/Controller/PhoneService";
import "./EditResume.scss";
import TypeSkillsCosmo from "./TypeSkills/TypeSkillsCosmo";

/* Validation form detail cosmonaute */
const validationDetailCandidat = Yup.object({
  name: Yup.string().required("Nom obligatoires"),
  lastname: Yup.string().required("Prénom obligatoires"),
  numberyears: Yup.string()
    .required("Nb années d'expériences obligatoire")
    .matches(numberPattern, "Nb années d'expériences invalide")
    .max(2, "Nb années d'expériences ne doit pas dépasser 2 chiffres"),
  phoneNumber: Yup.string()
    .matches(phonePattern, "Numéro de téléphone doit commencer par +33 et ne doit contenir que des chiffres")
    .min(11, "Numéro de téléphone doit être entre 11 et 15 caractères")
    .max(15, "Mot de passe doit être entre 11 et 15 chiffres"),
  email: Yup.string()
    .required("Email obligatoire")
    .email("Email invalide")
    .matches(emailPattern, "Email invalide"),
  url: Yup.string().url("Site web invalide")
});

export default function EditResume() {
  /* Context API */
  const {
    ProfilCosmonaute,
    UpdateCosmonaute,
    dumpSkillsCandidte,
    dumpExperience,
    dumpEducation,
    dumpLanguesCandidte,
    logoUploadCandidate,
    getLogoCandidate,
    dumpLogoCandidate,
    hideToast,
    getListRegions,
    getAllLangues,
    getAllSkills,
    getAllTypeSkills,
    state,
  } = useContext(CosmonauteContext);

  /* use Translation */
  const { t } = useTranslation();

  /* Hitory */
  const history = useHistory();

  /* States */
  const [modalShow, setModalShow] = useState(false);
  const [cosmonauteProfil, setCosmonauteProfil] = useState(null);
  const [candidateLogo, setCandidateLogo] = useState(null);
  const [cosmonauteExperiences, setCosmonauteExperiences] = useState(null);
  const [cosmonauteEducation, setCosmonauteEducations] = useState(null);
  const [cosmonauteSkills, setCosmonauteSkills] = useState(null);
  const [cosmonauteLangues, setCosmonauteLangues] = useState(null);
  const [cosmonauteTypeSkills, setCosmonauteTypeSkills] = useState(null);
  const [idProfil, setIdProfile] = useState(null);
  const [allRegions, setAllRegions] = useState([]);
  const [allLangues, setAllLangues] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [allTypeSkills, setAllTypeSkills] = useState([]);

                
  /* eslint-disable */
  useEffect(() => {
    getListRegions().then((res) => { setAllRegions(res.data) });
    getAllLangues();
    getAllSkills();
    getAllTypeSkills();
    const id = isAdmin() ? location.pathname.split("/")[3] : location.pathname.split("/")[2];
    if (id) {
      setIdProfile(id);
      ProfilCosmonaute(id);
      getLogoCandidate(id);
    }
  }, []);
  /* eslint-enable */

  /* use effect change State */
  useEffect(() => {
    if (state.CosmonauteProfil) setCosmonauteProfil(state.CosmonauteProfil);
    if (state.WorkExperiences) setCosmonauteExperiences(state.WorkExperiences);
    if (state.educationsCandidate) setCosmonauteEducations(state.educationsCandidate);
    if (state.skillsprofil) setCosmonauteSkills(state.skillsprofil);
    if (state.logoCandidate) setCandidateLogo(state.logoCandidate);
    if (state.Langues) setCosmonauteLangues(state.Langues);
    if (state.allTypeSkills) setCosmonauteTypeSkills(state.allTypeSkills);
    if (state.allLangues) setAllLangues(state.allLangues);
    if (state.listSkills) {
      const list = state.listSkills.map((el) => {
        return { value: el.id, label: el.name }
      })
      setAllSkills(list);
    }
    console.log(state)
    // if (state.allTypeSkills) {
      const listType = state?.allTypeSkills?.map((el) => {
        return { value: el?.id, label: el?.name }
      })
      setAllTypeSkills(listType);
    // }
    
  }, [state]);
console.log(allTypeSkills,"hi")
  /* refresh Skills */
  useEffect(() => {
    if (state.skillsCandidate) {
      ProfilCosmonaute(idProfil);
      dumpSkillsCandidte();
    }
  }, [dumpSkillsCandidte, ProfilCosmonaute, idProfil, state.skillsCandidate]);

  /* refresh Works */
  useEffect(() => {
    if (state.experience) {
      ProfilCosmonaute(idProfil);
      dumpExperience();
    }
  }, [dumpExperience, ProfilCosmonaute, idProfil, state.experience]);

  /* refresh Langues */
  useEffect(() => {
    if (state.languesCandidate) {
      ProfilCosmonaute(idProfil);
      dumpLanguesCandidte();
    }
  }, [dumpLanguesCandidte, ProfilCosmonaute, idProfil, state.languesCandidate]);

  /* refresh educations */
  useEffect(() => {
    if (state.educations) {
      ProfilCosmonaute(idProfil);
      dumpEducation();
    }
  }, [dumpEducation, ProfilCosmonaute, idProfil, state.educations]);

  /* refresh Logo Candidate */
  useEffect(() => {
    if (state.logo) {
      getLogoCandidate(idProfil);
      dumpLogoCandidate();
    }
  }, [dumpLogoCandidate, getLogoCandidate, idProfil, state.logo]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);

  /* Edit CV Candidate */
  const showResume = () => {
    if (isAdmin()) {
      history.push(t("role.administrator") + t("sidebar.inspirnautes.path") + "/" + idProfil + "/resume");
    }
    else history.push(t("role.user") + "/resume/" + idProfil);
  };

  /* Return Back to Profil Candidate */
  const returnBackCosmo = () => {
    history.push(t("role.user") + t("sidebar.profil.path"));
  };

  /* Return back to Admin */
  function returnBackAdmin() {
    history.push(t("role.administrator") + t("sidebar.inspirnautes.path") + "/" + idProfil + "/resume");
  }

  /* Candidate Form */
  const profilCandidateForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: cosmonauteProfil && cosmonauteProfil.name ? cosmonauteProfil.name : "",
      lastname: cosmonauteProfil && cosmonauteProfil.lastname ? cosmonauteProfil.lastname : "",
      managerPost:
        cosmonauteProfil && cosmonauteProfil.poste
          ? cosmonauteProfil.poste
          : "",
      place: cosmonauteProfil && cosmonauteProfil.country ? cosmonauteProfil.country : "",
      numberyears:
        cosmonauteProfil && cosmonauteProfil.nbrAnneeExp
          ? cosmonauteProfil.nbrAnneeExp
          : "",
      phoneNumber:
        cosmonauteProfil && cosmonauteProfil.phone
          ? cosmonauteProfil.phone
          : "",
      email:
        cosmonauteProfil && cosmonauteProfil.email
          ? cosmonauteProfil.email
          : "",
      url:
        cosmonauteProfil && cosmonauteProfil.link ? cosmonauteProfil.link : "",
    },
    validationSchema: validationDetailCandidat,
    onSubmit: (values) => {
      var datasend = {
        name: values.name,
        lastname: values.lastname,
        poste: values.managerPost,
        nbrAnneeExp: values.numberyears,
        country: values.place,
        phone: values.phoneNumber,
        email: values.email,
        link: values.url,
        id: idProfil
      };
      UpdateCosmonaute(datasend);
    },
  });

  /* Edit profile header */
  function EditResumeHeader() {
    return (
      <>
        <Form onSubmit={profilCandidateForm.handleSubmit}>
          <Row className="align-items-center">
            <Col id="profilePic" lg={2} md={4} xs={12} className="text-align-center pr-form mt-5">
              <Image
                className="p-18 w-height-image"
                src={candidateLogo ? "http://" + candidateLogo.path : astro}
                roundedCircle
              />
              <div className="text-camera text-align-center mb-5">
                <i className="icon-camera pointer" onClick={() => { setModalShow(true) }}> </i>
                Changez votre photo de profil
              </div>
            </Col>
            <Col lg={10} md={8} xs={12}>
              <Row>
                <Col lg={6} md={12} xs={12} className="pr-form">
                  {/* cosmonaute Name */}
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control
                          className={"input-header" + ErrorClass(profilCandidateForm, "lastname")}
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Enter your Name"
                          onChange={profilCandidateForm.handleChange}
                          value={profilCandidateForm.values.lastname}
                        />
                        {ErrorFeild(profilCandidateForm, "lastname")}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                          className={"input-header" + ErrorClass(profilCandidateForm, "name")}
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter your Name"
                          onChange={profilCandidateForm.handleChange}
                          value={profilCandidateForm.values.name}
                        />
                        {ErrorFeild(profilCandidateForm, "name")}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* cosmonaute  Manager Post */}
                  <Form.Group>
                    <Form.Label>Poste actuel</Form.Label>
                    <Form.Control
                      className={"input-header" + ErrorClass(profilCandidateForm, "managerPost")}
                      type="text"
                      name="managerPost"
                      id="managerPost"
                      placeholder="Enter your current position"
                      onChange={profilCandidateForm.handleChange}
                      value={profilCandidateForm.values.managerPost}
                    />
                    {ErrorFeild(profilCandidateForm, "managerPost")}
                  </Form.Group>

                  {/*  Number of years of flight */}
                  <Form.Group>
                    <Form.Label>Nombre d'années d'expériences</Form.Label>
                    <Form.Control
                      className={"input-header" + ErrorClass(profilCandidateForm, "numberyears")}
                      type="text"
                      name="numberyears"
                      id="numberyears"
                      placeholder="Enter your number of experience years "
                      onChange={profilCandidateForm.handleChange}
                      value={profilCandidateForm.values.numberyears}
                    />
                    {ErrorFeild(profilCandidateForm, "numberyears")}
                  </Form.Group>

                  {/* Région */}
                  <Form.Group>
                    <Form.Label>Région</Form.Label>
                    <Form.Select className={'input-header ' + ErrorClass(profilCandidateForm, 'place')} name="place" id="place"
                      onChange={profilCandidateForm.handleChange}
                      onBlur={profilCandidateForm.handleBlur}
                      value={profilCandidateForm.values.place}>
                      <option className="text-bold color-black" value={""}> Séléctionnez une région </option>
                      {
                        allRegions && allRegions.length
                          ? allRegions.map((el, index) => {
                            return (
                              <option className="color-black" key={"option" + index} value={el.nom}>
                                {el.nom}
                              </option>
                            )

                          })
                          : null
                      }
                    </Form.Select>
                    {ErrorFeild(profilCandidateForm, "place")}
                  </Form.Group>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  {/* Phone number cosmonaute */}
                  <Form.Group className="position-relative">
                    <Form.Label>Numéro de téléphone</Form.Label>
                    <i className="icon-call position-absolute icon-input"></i>
                    <Form.Control
                      className={"input-header" + ErrorClass(profilCandidateForm, "phoneNumber")}
                      type="text" name="phoneNumber" id="phoneNumber"
                      placeholder="Enter your Phone number"
                      onChange={profilCandidateForm.handleChange}
                      onBlur={() => { onBlurPhoneNumber(profilCandidateForm, "phoneNumber") }}
                      onFocus={() => { onFocusPhoneNumber(profilCandidateForm, "phoneNumber") }}
                      value={profilCandidateForm.values.phoneNumber}
                    />
                    {ErrorFeild(profilCandidateForm, "phoneNumber")}
                  </Form.Group>

                  {/* Email cosmonaute */}
                  <Form.Group className="position-relative">
                    <Form.Label>Email</Form.Label>
                    <i className="icon-email position-absolute icon-input"></i>
                    <Form.Control
                      className={"input-header" + ErrorClass(profilCandidateForm, "email")}
                      type="email" name="email" id="email"
                      placeholder="Enter your Email"
                      onChange={profilCandidateForm.handleChange}
                      value={profilCandidateForm.values.email}
                    />
                    {ErrorFeild(profilCandidateForm, "email")}
                  </Form.Group>

                  {/* URL Web Site */}
                  <Form.Group className="position-relative">
                    <Form.Label>URL</Form.Label>
                    <i className="icon-url position-absolute icon-input"></i>
                    <Form.Control
                      className={
                        "input-header" + ErrorClass(profilCandidateForm, "url")
                      }
                      type="text"
                      name="url"
                      id="url"
                      placeholder="Enter your URL"
                      onChange={profilCandidateForm.handleChange}
                      value={profilCandidateForm.values.url}
                    />
                    {ErrorFeild(profilCandidateForm, "url")}
                  </Form.Group>

                  {/* Save Form */}
                  <Button type="submit" className="shadow-unset position-relative mt-4 float-right" >
                    Sauvegarder
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  /* edit profile function */
  function EditResumeMain() {
    /* Form Group Profile Creation */
    const formik = useFormik({
      enableReinitialize: true,
      // initialValues: {
      //   title: edit ? edit.title : "",
      //   nbrYearsExperience: edit ? edit.nbrYearsExperience : "",
      //   place: edit ? edit.place : "",
      //   dateStart: edit ? edit.dateStart : "",
      //   domain: edit ? edit.domain : "",
      //   type: edit ? edit.type : "",
      //   nom_de_la_formation : edit ? edit.nom_de_la_formation : "",
      //   nbr_participants : edit ? edit.nbr_participants : "",
      //   dateEnd: edit ? edit.dateEnd : "",
      //   description: edit ? edit.description : "",
      //   renewable: edit ? edit.renewable : "",
      //   skills:
      //     edit && edit.skills && edit.skills.length
      //       ? edit.skills
      //           .filter((item) => item.priority === 1)
      //           .map((el) => {
      //             return {
      //               value: el.id,
      //               label: el.name,
      //               rating: el.rating,
      //               priority: el.priority,
      //             };
      //           })
      //       : [],
      //   skillSup:
      //     edit && edit.skills && edit.skills.length
      //       ? edit.skills
      //           .filter((item) => item.priority === 0)
      //           .map((el) => {
      //             return {
      //               value: el.id,
      //               label: el.name,
      //               rating: el.rating,
      //               priority: el.priority,
      //             };
      //           })
      //       : [],
      // },
      // validationSchema: validationSchema,
      onSubmit: (values) => {
      
        // var data = {
        //   // dateEnd: values.dateEnd,
        //   // renewable: values.renewable,
        //   // nbrYearsExperience: values.nbrYearsExperience,
        //   // title: values.title,
        //   skills: values.skills,
        //   skillSup: values.skillSup,
        //   dateStart: values.dateStart,
        //   description: values.description,
        //   place: values.place,
        //   type:values.type,
        //   domain: values.domain,
        //   nom_de_la_formation: values.nom_de_la_formation,
        //   nbr_participants: values.nbr_participants,
        // };
        
      },
    });
    return (
      <>
        <Row>
          <Col xs={12} md={12} lg={7} className="pr-form mt-3">
            <ExperienceCandidate cosmonauteExperiences={cosmonauteExperiences} allSkills={allSkills} id={idProfil} />
          </Col>

          <Col xs={12} md={12} lg={5} className="pl-form mt-3">
            <EducationCandidate cosmonauteEducation={cosmonauteEducation} id={idProfil} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={7} className="pr-form mt-3">
            {/* <SkillsCandidate cosmonauteSkills={cosmonauteSkills} id={idProfil} /> */}
            <TypeSkillsCosmo allTypeSkills={allTypeSkills} cosmonauteTypeSkills={cosmonauteTypeSkills} setAllTypeSkills={setAllTypeSkills} id={idProfil} />
          </Col>
          <Col xs={12} md={12} lg={5} className="pl-form mt-3">
            <LanguesCandidate cosmonauteLangues={cosmonauteLangues} allLangues={allLangues} id={idProfil} />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      {/* Toast Service */}
      <ToastService data={state.toastContent} />

      {/* Upload Logo Candidate */}
      <ModalUploadLogo
        modalShow={modalShow}
        setModalShow={setModalShow}
        logoUpload={logoUploadCandidate}
      />

      {/* Header Resume */}
      <div id="header-Content" className="header-Content">
        <Row className="align-items-center mt-3">
          <Col lg={8} md={8} xs={12}>
            {isAdmin() ? (
              <div className="pointer" onClick={returnBackAdmin}>
                <i className="icon-back-arrow p-3"></i>
                <span>Cv d'inspirnaute</span>
              </div>
            ) : (
              <div className="pointer" onClick={returnBackCosmo}>
                <i className="icon-back-arrow p-3"></i>
                <span>PROFILE / CV</span>
              </div>
            )}
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Button ype="button" className="float-right save-button green shadow-unset" onClick={showResume}>
              Voir le CV
            </Button>
          </Col>
        </Row>
        {EditResumeHeader()}
      </div>

      {/* Main Resume */}
      <div id="main" className="main">
        {EditResumeMain()}
      </div>
    </>
  );
}
