import { API_HOST, API_TYPE_SKILLS_ADMINISTRATOR } from "../../Api";
import { SHOW_TOAST } from '../constants/Toast';
import { ALL_TYPE_SKILLS_ADMIN, ADD_TYPE_SKILL_ADMIN, EDIT_TYPE_SKILL_ADMIN, DELETE_TYPE_SKILL_ADMIN, DUMP_TYPE_SKILL } from '../constants/Administrator';
import axiosServices from "../../services/Axios/axiosServices";

/* Get list TYPE_Skills */
const getAllTypeSkills = (dispatch) => () => {
    // URL get list TYPE_Skills
    const URL = API_HOST + API_TYPE_SKILLS_ADMINISTRATOR;
    // Call API list TYPE_Skills
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ALL_TYPE_SKILLS_ADMIN, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste destypes Skills" }
            });
        }
    );
}

/* Add Type Skill */
const addTypeSkill = (dispatch) => (body) => {
    // URL add Type Skill
    const URL = API_HOST + API_TYPE_SKILLS_ADMINISTRATOR;
    // Call API add Type skill
    axiosServices.post(URL, body).then(
        (res) => {
            dispatch({
                type: ADD_TYPE_SKILL_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Type skill est ajouté avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: {
                    status: err.response && err.response.status === 409 ? 'warning' : 'error',
                    body: err.response && err.response.status === 409 ? "Type skill déjà existe" : "Echec d'ajout Type Skill"
                }
            });
        }
    );
}

/* Edit Type Skill */
const editTypeSkill = (dispatch) => (body, id) => {
    // URL add Type Skill
    const URL = API_HOST + API_TYPE_SKILLS_ADMINISTRATOR + '/' + id;
    // Call API add Type skill
    axiosServices.post(URL, body).then(
        (res) => {
            dispatch({
                type: EDIT_TYPE_SKILL_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Type skill est modifié avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: {
                    status: err.response && err.response.status === 409 ? 'warning' : 'error',
                    body: err.response && err.response.status === 409 ? "Type skill déjà existe" : "Echec de modification Type Skill"
                }
            });
        }
    );
}

/* Delete Type Skill */
const deleteTypeSkill = (dispatch) => (id) => {
    // URL delete Type Skill
    const URL = API_HOST + API_TYPE_SKILLS_ADMINISTRATOR + '/' + id;
    // Call API delete Type skill
    axiosServices.delete(URL).then(
        (res) => {
            dispatch({
                type: DELETE_TYPE_SKILL_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Type skill est supprimé avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de suppression Type Skill" }
            });
        }
    );
}

/* Dump Type Skill */
const dumpTypeSkill = (dispatch) => () => {
    dispatch({ type: DUMP_TYPE_SKILL });
}

/* Export Action Type Skills for Administrator */
export { getAllTypeSkills, addTypeSkill, editTypeSkill, deleteTypeSkill, dumpTypeSkill };