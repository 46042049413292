import React, { useContext, useEffect, useState } from 'react';
import { FaRegCalendarMinus, FaMapMarkerAlt, FaRegCheckCircle } from 'react-icons/fa';
import { Button, Row, Col, ProgressBar, Image, Card, Dropdown } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Context as RecruiterContext } from '../../../context/reducers/RecruiterReducer';
import { formatTime } from '../../../services/Controller/DateFormatter';
import ToastService from '../../../components/Toast/ToastService';
import NothingToShow from '../../../components/NothingToShow/NothingToShow';
import ModalInterview from '../../../components/Interview/ModalInterview';
import { ModalMission } from '../../../components/Mission/ModalMission';
import AvatarGroup from '../../../components/avatar/avatarGroup';
import campany from '../../../assets/img/campany.png';
import ModalConfirm from '../../../components/ModalConfirm/ModalConfirm';
import './DashboardRecruiter.scss';
import { format } from 'date-fns';


/* Dashboard Content */
function DashboardRecruiter() {
  /* Translation */
  const { t } = useTranslation();
  /* History */
  const history = useHistory();
  /* Context Api */
  const {
    getFirstThreeProposedMissions, getFirstThreeUpcommingInterview, getFirstThreeOngoingMission, getDashboardRecruiter,
    addMission, deleteMission, interruptMission, dumpMission,
    declineInterviewRecruiter, dumpInterview,
    getLogoRecruiter, pourcentageRecruiter,
    hideToast, state } = useContext(RecruiterContext);
  /* States */
  /* Variables */
  const [detailDashboard, setDetailDashboard] = useState(null);
  const [proposedMissions, setProposedMissions] = useState([]);
  const [ongoingMissions, setOngoingMissions] = useState([]);
  const [upcommingInterviews, setUpcommingInterviews] = useState([]);
  const [recruiterLogo, setRecruiterLogo] = useState(null);
  /* Modal Mission  */
  const [modalShow, setModalShow] = useState(false);
  /* Modal Interview */
  const [modalDateInterviewShow, setModalDateInterviewShow] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  /* Modal Confirm */
  const [show, setShow] = useState(false);
  /* Modal Title */
  const [titleModal, setTitleModal] = useState(null);
  /* Mode Current Action */
  const [mode, setMode] = useState(false);
  /* seleted item */
  const [selectedItem, setSelectedItem] = useState(null);
  /* Mission Detail */
  const [oneMission, setOneMission] = useState(null);
  const [pourcentageProfile, setPourcentageProfile] = useState(null);

  /* eslint-disable */
  useEffect(() => {
    getDashboardRecruiter();
    getLogoRecruiter();
    getFirstThreeProposedMissions();
    getFirstThreeUpcommingInterview();
    getFirstThreeOngoingMission();
    pourcentageRecruiter()
  }, []);
  /* eslint-enable */

  /* get states */
  useEffect(() => {
    if (state.infoDashboard) setDetailDashboard(state.infoDashboard);
    if (state.recruiterLogo) setRecruiterLogo(state.recruiterLogo);
    if (state.firstThreeProposedMissions) setProposedMissions(state.firstThreeProposedMissions);
    if (state.firstThreeOngoingMissions) setOngoingMissions(state.firstThreeOngoingMissions);
    if (state.firstThreeUpcommingInterviews) setUpcommingInterviews(state.firstThreeUpcommingInterviews);
    if (state.pourcentage) setPourcentageProfile(state.pourcentage.percentage)
  }, [state]);

  /* refresh Proposed Mission */
  useEffect(() => {
    if (state.mission) {
      getFirstThreeProposedMissions();
      getFirstThreeOngoingMission();
      dumpMission();
    }
  }, [dumpMission, getFirstThreeProposedMissions, getFirstThreeOngoingMission, state.mission]);

  /* refresh after change | Reject Interview */
  useEffect(() => {
    if (state.interview) {
      getFirstThreeUpcommingInterview();
      dumpInterview();
    }
  }, [dumpInterview, getFirstThreeUpcommingInterview, state.interview]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);

  /* Edit Profil */
  function redirectTo(to) {
    if (to === "profile") history.push(t('role.recruiter') + t('sidebar.profil.path'));
    else if (to === "interviews") history.push({ pathname: t('role.recruiter') + t('sidebar.interviews.path'), state: to });
    else history.push({ pathname: t('role.recruiter') + t('sidebar.missions.path'), state: to });
  }
  /* Détail Mission */
  function redirectToMission(id) {
    history.push(t('role.recruiter') + t('sidebar.missions.path') + '/' + id);
  }
  /* Open Modal Interview */
  function openModalInterview(interview, title, type) {
    setTitleModal(title);
    setSelectedItem(interview);
    setOneMission({
      title: interview.title,
      entreprise: {
        username: interview.company_name
      },
      date_start: interview.dateStart,
      date_end: interview.dateEnd,
      place: interview.place
    });
    setTypeModal(type);
    setModalDateInterviewShow(true);
  }

  /* Dashboard Header */
  function ShowDashboardHeader() {
    return (
      <>
        <Row>
          <Col className="margin-top-block" lg={8} md={12} xs={12}>
            <Card className="card-recruiter">
              <Card.Body>
                <Row className="align-items-center pl-3">
                  <Col lg={9} md={8} xs={9}>
                    <label className="card-title">
                      <span className="text-uppercase"> {detailDashboard ? detailDashboard.fullName : "- - -"} </span>
                      Bienvenue à nouveau !
                    </label>
                    <p className="text-Complete">Complétez votre profil et mettez-le à jour.</p>
                    <ProgressBar now={pourcentageProfile} />
                    <div className="mt-4">
                      <Button className="save-button green roboto-bold" onClick={() => redirectTo("profile")} >
                        Modifier profil
                      </Button>
                    </div>
                  </Col>
                  <Col id="profilePic" lg={3} md={4} xs={3} className="display-mobile-logo">
                    <Image className="p-image-18 w-height-image" src={recruiterLogo ? ('http://' + recruiterLogo.path) : campany} roundedCircle />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="margin-top-block" lg={4} md={12} xs={12}>
            <Card className="card-blue">
              <Card.Body className="p-4">
                <label className="card-label">Vous recherchez une formation ou un formateur spécifique ?</label>
                <Row className="justify-content-center mt-4">
                  <Col className="text-Mission mb-3" lg={8} md={10} xs={12}>
                  Créez une mission, décrivez ce que vous recherchez et nous vous aiderons à trouver les formateurs qui vous conviennent
                  </Col>
                  <Col lg={4} md={2} xs={4}>
                    <i className="icon-more blockPlusMission pointer" onClick={() => setModalShow(true)}></i>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={3} md={6} xs={6} className="lilCard">
            <Card className="card-redirection" onClick={() => redirectTo("proposed")}>
              <Card.Body>
                <div className="text-Number">{detailDashboard && detailDashboard.proposedMissions ? detailDashboard.proposedMissions : 0}</div>
                <span className="text-mission-Number">Missions proposées</span>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={6} xs={6} className="lilCard">
            <Card className="card-redirection" onClick={() => redirectTo("finished")}>
              <Card.Body>
                <div className="text-Number">{detailDashboard && detailDashboard.completedMission ? detailDashboard.completedMission : 0}</div>
                <span className="text-mission-Number">Missions terminées</span>
              </Card.Body>
            </Card>
          </Col>
          <Col className="margin-top-block lilCard" lg={3} md={6} xs={6}>
            <Card className="card-redirection" onClick={() => redirectTo("ongoing")}>
              <Card.Body>
                <div className="text-Number">{detailDashboard && detailDashboard.preselectedCondidat ? detailDashboard.preselectedCondidat : 0}</div>
                <span className="text-mission-Number">Formateurs présélectionnés</span>
              </Card.Body>
            </Card>
          </Col>
          <Col className="margin-top-block lilCard" lg={3} md={6} xs={6}>
            <Card className="card-redirection" onClick={() => redirectTo("interviews")}>
              <Card.Body>
                <div className="text-Number">{detailDashboard && detailDashboard.interview ? detailDashboard.interview : 0}</div>
                <span className="text-mission-Number">Formations</span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    )
  }

  /* Dashboard Content */
  function ShowDashboardContent() {
    return (
      <Row>

        {/* Proposed Missions */}
        <Col className="pr-dashboard" lg={6} md={12} xs={12}>
          <Row className="justify-content-center tag mb-4">
            <Col lg={10} md={10} xs={10}>
              <label className="title-label">Missions proposées</label>
            </Col>
            <Col lg={2} md={2} xs={2}>
              <label className="text-length blue">{proposedMissions ? proposedMissions.length : 0}</label>
            </Col>
          </Row>
          {proposedMissions && proposedMissions.length ? threeProposedMission() : <NothingToShow />}
        </Col>

        {/* Upcoming Interviews */}
        <Col className="pr-dashboard pl-dashboard" lg={3} md={6} xs={12}>
          <Row className="justify-content-center tag mb-4">
            <Col lg={10} md={10} xs={10}>
              <label className="title-label pink">Formations à venir</label>
            </Col>
            <Col lg={2} md={2} xs={2}>
              <label className="text-length pink">{upcommingInterviews ? upcommingInterviews.length : 0}</label>
            </Col>
          </Row>
          {upcommingInterviews && upcommingInterviews.length ? threeUpcommingInterview() : <NothingToShow />}
        </Col>
        {/* Ongoing Missions */}
        <Col className="pl-dashboard" lg={3} md={6} xs={12}>
          <Row className="justify-content-center tag mb-4">
            <Col lg={10} md={10} xs={10}>
              <label className="title-label green">Missions en cours</label>
            </Col>
            <Col lg={2} md={2} xs={2}>
              <label className="text-length green">{ongoingMissions ? ongoingMissions.length : 0}</label>
            </Col>
          </Row>
          {ongoingMissions && ongoingMissions.length ? threeOngoingMission() : <NothingToShow />}
        </Col>
      </Row>
    )
  }

  /* Three Proposed Missions */
  function threeProposedMission() {
    return (
      proposedMissions.map((el, index) => {
        return (
          <Card className="card-content mb-3" key={"proposedMissions" + index}>
            <Card.Body>
              <Row>
                <Col lg={10} md={10} xs={10}>
                  <label className="text-header mr-20 link" onClick={() => { redirectToMission(el.mission.id) }}>{el && el.mission ? el.mission.title : '- - -'}</label>
                  <label className="text-tag blue margin-top-avatar">{el && el.profils ? el.profils.length : 0} profils proposés</label>
                </Col>
                <Col lg={2} md={2} xs={2}>
                  <Dropdown className="float-right" align="end">
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="icon-see-more"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="text-calendar" as="button" onClick={() => { onSelectItem("Delete", el) }}>
                        Supprimer mission
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row className="mb-3 margin-top-avatar">
                <AvatarGroup size="sm" profils={el && el.profils ? el.profils : []} />
              </Row>
              <Row>
                <div className="text-detail d-flex mb-2">
                  < FaRegCalendarMinus />
                  <span className="ml-10 mr-20">
                    Du : {el && el.mission ? format(new Date(el.mission.dateStartFormat), "dd/MM/yyyy") : '- - -'}
                    - Au: {el && el.mission ? format(new Date(el.mission.dateEndFormat), "dd/MM/yyyy") : '- - -'}
                  </span>
                </div>
                <div className="text-detail d-flex mb-3">
                  < FaMapMarkerAlt />
                  <span className="ml-10 mr-20"> Lieu : {el && el.mission ? el.mission.place : '- - -'}  </span>
                </div>
              </Row>
            </Card.Body>
          </Card>
        );
      })
    )
  }

  /* Three Upcomming Interviews */
  function threeUpcommingInterview() {
    return (
      upcommingInterviews.map((el, index) => {
        return (
          <Card className="card-content mb-3" key={"upcommingInterviews" + index}>
            <Card.Body>
              <div className="d-flex justify-space-between align-items-center mb-3">
                {
                  el.name && el.lastname
                    ? <AvatarGroup className="float-left" size="sm" maxitems={2} profils={[el]} />
                    : null
                }
                <label className={"text-header link" + (el.name && el.lastname ? " ml-20" : "")} onClick={() => { redirectToMission(el.missionId) }}>
                  {el && el.title ? el.title : '- - -'}
                </label>
                <Dropdown className="float-right" align="end">
                  <Dropdown.Toggle id="dropdown-basic">
                    <i className="icon-see-more"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className="text-calendar" as="button"
                      onClick={() => openModalInterview(el, "Vous êtes sûr de vouloir rejeter ce candidat ?", "decline")}>
                      Rejeter entretien
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="text-detail d-flex mb-2">
                < FaRegCalendarMinus />
                <span className="ml-10 mr-20">
                  Le {el && el.selectedDate ? format(new Date(el.selectedDate), "dd/MM/yyyy") : '- - -'},
                  à {el && el.selectedDate ? formatTime(new Date(el.selectedDate)) : '- - -'}
                </span>
              </div>
              <div className="text-detail d-flex mb-3">
                < FaMapMarkerAlt />
                <span className="ml-10 mr-20"> {el && el.place ? el.place : '- - -'}  </span>
              </div>
            </Card.Body>
          </Card>
        );
      })
    )
  }

  /* Three Ongoing Missions */
  function threeOngoingMission() {
    return (
      ongoingMissions.map((el, index) => {
        return (
          <Card className="card-content mb-3" key={"ongoingMissions" + index}>
            <Card.Body>
              <div className="d-flex justify-space-between align-items-center mb-3">
                {
                  el.profils && el.profils.length
                    ? <AvatarGroup className="float-left" size="sm" maxitems={2} profils={el && el.profils ? el.profils : []} />
                    : null
                }
                <label className={"text-header link" + (el.profils && el.profils.length ? " ml-20" : "")} onClick={() => { redirectToMission(el.mission.id) }} >
                  {el && el.mission ? el.mission.title : '- - -'}
                </label>
                <Dropdown className="float-right" align="end">
                  <Dropdown.Toggle id="dropdown-basic">
                    <i className="icon-see-more"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className="text-calendar" as="button" onClick={() => { onSelectItem("Interrupt", el) }}>
                      Interrompre mission
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="text-detail d-flex mb-2">
                < FaRegCalendarMinus />
                <span className="ml-10 mr-20"> Du : {el && el.mission ? format(new Date(el.mission.dateStartFormat), "dd/MM/yyyy") : '- - -'} </span>
              </div>
              <div className="text-detail d-flex mb-3">
                < FaRegCheckCircle />
                <span className="ml-10 mr-20"> Au :  {el && el.mission ? format(new Date(el.mission.dateEndFormat), "dd/MM/yyyy") : '- - -'}  </span>
              </div>
            </Card.Body>
          </Card >
        );
      })
    )
  }

  /* Select item */
  function onSelectItem(type, mission) {
    setMode(type);
    setSelectedItem(mission);
    setTitleModal("Vous êtes sûr de vouloir " + (type === "Interrupt" ? "interrompre" : "supprimer") + " cette mission ?");
    setShow(true);
  }

  /* Delete | Interrupt Mission */
  function confirmAction() {
    if (mode === "Interrupt") interruptMission(selectedItem.mission.id);
    else if (mode === "Delete") deleteMission(selectedItem.mission.id);
  }

  return (
    <>
      {/* Interview Modal */}
      <ModalInterview
        title={titleModal} type={typeModal} mission={oneMission} selectedItem={selectedItem}
        modalDateInterviewShow={modalDateInterviewShow} setModalDateInterviewShow={setModalDateInterviewShow}
        declineInterviewRecruiter={declineInterviewRecruiter} />
      {/* Confrm Modal */}
      <ModalConfirm show={show} setShow={setShow} message={titleModal}
        setSelectedItem={setSelectedItem} Action={confirmAction} />
      {/* Mission Modal */}
      <ModalMission
        modalShow={modalShow} setModalShow={setModalShow}
        addMission={addMission} />
      <ToastService data={state.toastContent} />
      <div id="header-Content" className="header-Content">
        {ShowDashboardHeader()}
      </div>
      <div id="main" className="main">
        {ShowDashboardContent()}
      </div>
    </>
  );
};

/* Export Dashboard recruiter component */
export default DashboardRecruiter;
