import { API_HOST, API_DOMAIN_RECRUITER_ADMINISTRATOR } from "../../Api";
import { SHOW_TOAST } from '../constants/Toast';
import { ALL_DOMAIN_RECRUITER_ADMIN, ADD_DOMAIN_RECRUITER_ADMIN, EDIT_DOMAIN_RECRUITER_ADMIN, DELETE_DOMAIN_RECRUITER_ADMIN, DUMP_DOMAIN_RECRUITER_ADMIN } from '../constants/Administrator';
import axiosServices from "../../services/Axios/axiosServices";

/* Get list DOMAIN_RECRUITER */
const getAllDomainsRecruiter = (dispatch) => () => {
    // URL get list DOMAIN_RECRUITER
    const URL = API_HOST + API_DOMAIN_RECRUITER_ADMINISTRATOR;
    // Call API list DOMAIN_RECRUITER
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ALL_DOMAIN_RECRUITER_ADMIN, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { 
                    status: err.response && err.response.status === 409 ? 'warning' : 'error',
                    body: err.response && err.response.status === 409 ? "Domaine Entreprise  déjà existe" : "Echec de récupération de la liste des domaines Entreprise s" 
                }
            });
        }
    );
}

/* Add DOMAIN_RECRUITER */
const addDomainsRecruiter = (dispatch) => (body) => {
    // URL add DOMAIN_RECRUITER
    const URL = API_HOST + API_DOMAIN_RECRUITER_ADMINISTRATOR;
    // Call API add DOMAIN_RECRUITER
    axiosServices.post(URL, body).then(
        (res) => {
            dispatch({
                type: ADD_DOMAIN_RECRUITER_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Domaine Entreprise  est ajouté avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { 
                    status: err.response && err.response.status === 409 ? 'warning' : 'error',
                    body: err.response && err.response.status === 409 ? "Domaine Entreprise  déjà existe" : "Echec d'ajout domaine Entreprise " 
                }
            });
        }
    );
}

/* Edit DOMAIN_RECRUITER */
const editDomainsRecruiter = (dispatch) => (body, id) => {
    // URL add DOMAIN_RECRUITER
    const URL = API_HOST + API_DOMAIN_RECRUITER_ADMINISTRATOR + '/' + id;
    // Call API add DOMAIN_RECRUITER
    axiosServices.post(URL, body).then(
        (res) => {
            dispatch({
                type: EDIT_DOMAIN_RECRUITER_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Domaine Entreprise  est modifié avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de modification domaine Entreprise " }
            });
        }
    );
}

/* Delete DOMAIN_RECRUITER */
const deleteDomainsRecruiter = (dispatch) => (id) => {
    // URL delete DOMAIN_RECRUITER
    const URL = API_HOST + API_DOMAIN_RECRUITER_ADMINISTRATOR + '/' + id;
    // Call API delete DOMAIN_RECRUITER
    axiosServices.delete(URL).then(
        (res) => {
            dispatch({
                type: DELETE_DOMAIN_RECRUITER_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "Domaine Entreprise  est supprimé avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de suppression domaine Entreprise " }
            });
        }
    );
}

/* Dump DOMAIN_RECRUITER */
const dumpDomainRecruiter = (dispatch) => () => {
    dispatch({ type: DUMP_DOMAIN_RECRUITER_ADMIN });
}

/* Export Action Domaines Entreprise s for Administrator */
export { getAllDomainsRecruiter, addDomainsRecruiter, editDomainsRecruiter, deleteDomainsRecruiter, dumpDomainRecruiter };